import { Link } from "react-router-dom";
import { styled } from "../../../../styles";

export const Actions = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 16,
  width: "100%",
  alignItems: "center",
});

export const LinkBoleto = styled(Link, {
  width: "100%",
  maxWidth: 374,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,
  color: "$primaryDark",
  border: "1.5px solid $primaryDark",
  borderRadius: 8,
  height: 47,
  textDecoration: "none",
  fontWeight: "$bold",
  lineHeight: "22px",
});
