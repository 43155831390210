export function Portion() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0263 3.86312C10.0263 2.645 11.0568 1.4619 12.4389 1.76321C15.6197 2.45663 18.1226 4.95949 18.816 8.14034C19.1173 9.52247 17.9342 10.5529 16.7161 10.5529H12.318C11.0524 10.5529 10.0263 9.52686 10.0263 8.26121V3.86312ZM12.1726 2.98452C11.7561 2.89371 11.2763 3.24029 11.2763 3.86312V8.26121C11.2763 8.8365 11.7427 9.30287 12.318 9.30287L16.7161 9.30287C17.3389 9.30287 17.6855 8.82316 17.5947 8.40659C17.0054 5.70349 14.8757 3.5738 12.1726 2.98452Z"
        fill="#076BEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.605 4.79711C7.59792 4.79601 7.58537 4.7953 7.56376 4.80378C5.20969 5.72846 3.54492 8.02109 3.54492 10.701C3.54492 14.1988 6.38043 17.0343 9.8782 17.0343C12.5581 17.0343 14.8508 15.3695 15.7754 13.0155C15.7839 12.9939 15.7832 12.9813 15.7821 12.9742C15.7807 12.9654 15.7764 12.9522 15.7646 12.9367C15.7401 12.9043 15.6907 12.8723 15.6205 12.8723H10.8319C9.10603 12.8723 7.70692 11.4732 7.70692 9.7473L7.70692 4.95877C7.70692 4.88852 7.6749 4.83915 7.64254 4.81461C7.62702 4.80284 7.61384 4.79848 7.605 4.79711ZM7.10675 3.64032C8.08282 3.25692 8.95692 4.05971 8.95692 4.95877V9.7473C8.95692 10.7828 9.79639 11.6223 10.8319 11.6223H15.6205C16.5195 11.6223 17.3223 12.4964 16.9389 13.4725C15.8327 16.2888 13.0892 18.2843 9.8782 18.2843C5.69007 18.2843 2.29492 14.8892 2.29492 10.701C2.29492 7.48999 4.29045 4.74656 7.10675 3.64032Z"
        fill="#076BEE"
      />
    </svg>
  );
}
