import { Loader } from "./styles";

export function Loading() {
  return (
    <Loader>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM7.0804 24C7.0804 33.3444 14.6556 40.9196 24 40.9196C33.3444 40.9196 40.9196 33.3444 40.9196 24C40.9196 14.6556 33.3444 7.0804 24 7.0804C14.6556 7.0804 7.0804 14.6556 7.0804 24Z"
          fill="#E8F1FF"
        />
        <path
          d="M44.46 24C46.4151 24 48.0267 22.4067 47.7394 20.4728C47.4514 18.5345 46.9264 16.6343 46.1731 14.8156C44.967 11.9038 43.1992 9.25804 40.9706 7.02944C38.742 4.80083 36.0962 3.033 33.1844 1.82689C31.3657 1.07358 29.4655 0.548605 27.5272 0.260605C25.5933 -0.0267298 24 1.58491 24 3.54C24 5.49509 25.6018 7.04291 27.5143 7.44897C28.5248 7.66354 29.516 7.97072 30.475 8.36796C32.5278 9.21827 34.3931 10.4646 35.9642 12.0358C37.5354 13.6069 38.7817 15.4722 39.632 17.525C40.0293 18.484 40.3365 19.4752 40.551 20.4857C40.9571 22.3982 42.5049 24 44.46 24Z"
          fill="#076BEE"
        />
      </svg>
    </Loader>
  );
}
