import api from "../api";
import {
  GetMelhorOfertaResponse,
  GetOpcoesParcelamentoParams,
  GetOpcoesParcelamentoResponse,
  PostEfetuarAcordoBody,
  PostEfetuarAcordoResponse,
} from "./types";

const PATH = "fatura/api/Renegociacao";

export const Renegociacao = {
  melhorOferta: () => api.get<GetMelhorOfertaResponse>(`${PATH}/MelhorOferta`),

  opcoesParcelamento: (params: GetOpcoesParcelamentoParams) =>
    api.get<GetOpcoesParcelamentoResponse>(`${PATH}/OpcoesParcelamento`, {
      params,
    }),

  efetivarAcordo: (params: PostEfetuarAcordoBody) =>
    api.post<PostEfetuarAcordoResponse>(`${PATH}/EfetivarAcordo`, params),
};
