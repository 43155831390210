import { styled } from "../../styles";

export const Icon = styled("span", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 18px",
});

export const Title = styled("p", {
  fontWeight: "$bold",
  fontSize: "$sm",
  lineHeight: "25px",
  color: "$neutralDarkest",
});

export const Description = styled("p", {
  lineHeight: "22px",
  color: "$neutralDark",
});

export const CloseButton = styled("button", {
  all: "unset",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: 16,
  right: 16,
  size: 20,

  "&:hover": {
    cursor: "pointer",
  },
});

export const Alerts = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 16,
  position: "fixed",
  maxWidth: "100vw",

  zIndex: 3,

  "@max_width_480": {
    top: 116,
  },

  "@min_width_1201": {
    bottom: 150,
    right: 24,
  },
});

export const Container = styled("div", {
  fontFamily: "$default",
  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.24)",
  borderRadius: 8,
  backgroundColor: "$neutralLighter",
  position: "relative",
  padding: "$4 52px  $4 $2",
  boxSizing: "border-box",
  display: "flex",

  "@max_width_480": {
    margin: "0 $4",
  },

  "@min_width_1201": {
    width: 586,
  },

  "&::before": {
    content: "",
    width: 8,
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: "8px 0 0 8px",
  },

  variants: {
    variant: {
      alert: {
        "&::before": {
          backgroundColor: "$plusOneDark",
        },
        [`${Icon}`]: {
          color: "$plusOneDark",
        },
      },
      error: {
        "&::before": {
          backgroundColor: "$plusTwoDark",
        },
        [`${Icon}`]: {
          color: "$plusTwoDark",
        },
      },
      info: {
        "&::before": {
          backgroundColor: "$primaryMedium",
        },
        [`${Icon}`]: {
          color: "$primaryMedium",
        },
      },
      success: {
        "&::before": {
          backgroundColor: "$secondaryMedium",
        },
        [`${Icon}`]: {
          color: "$secondaryMedium",
        },
      },
    },
  },
  defaultVariants: {
    variant: "info",
  },
});
