import * as Checkbox from "@radix-ui/react-checkbox";
import { styled } from "../../styles";

export const Wrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "$2",
});

export const Root = styled(Checkbox.Root, {
  all: "unset",
  border: "1px solid $primaryMedium",
  backgroundColor: "$neutralLighter",
  borderRadius: 4,
  width: 24,
  height: 24,
  flexShrink: 0,

  "&:hover:not(:disabled)": {
    boxShadow: "$colors$primary200 0px 0px 0px 3px",
  },

  '&[data-state="checked"]': {
    backgroundColor: "$primaryMedium",
  },
});

export const Indicator = styled(Checkbox.Indicator, {
  color: "$neutralLighter",
});
