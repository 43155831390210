import api from "../api";
import { GetBoletoResponse, PostPixResponse } from "./types";

const PATH = "fatura/api/PagamentoRenegociacao";

export const Pagamento = {
  boleto: () => api.get<GetBoletoResponse>(`${PATH}/Boleto`),

  pix: () => api.post<PostPixResponse>(`${PATH}/Pix`),
};
