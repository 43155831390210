import { styled } from "../../styles";

export const Container = styled("p", {
  padding: 8,
  textAlign: "center",
  backgroundColor: "$primaryLighter",
  borderRadius: 8,
  border: "1px solid $primaryLight",
  fontSize: "$xxs",
  lineHeight: "19px",
  color: "$primaryDarkest",
});
