import { styled } from "../../styles";

export const Root = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 24,
  backgroundColor: "$neutralLighter",
  border: "1.5px solid $primaryLight",
  borderRadius: 16,

  "@max_width_480": {
    padding: 16,
  },

  "@min_width_481": {
    padding: "20px 50px",
  },

  "@min_width_769": {
    padding: "40px 100px",
  },
});

export const Header = styled("h2", {
  display: "flex",
  alignItems: "center",
  gap: 8,
  fontWeight: "$extraBold",
  fontSize: "$sm",
  lineHeight: "25px",
  color: "$primaryMedium",
});
