export function ShieldCheck() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5022 9.59199C15.8139 9.86475 15.8455 10.3386 15.5727 10.6503L12.7028 13.9302C12.0861 14.635 11.0239 14.7294 10.2926 14.1443L8.53979 12.7421C8.21634 12.4833 8.1639 12.0113 8.42266 11.6879C8.68141 11.3645 9.15338 11.312 9.47683 11.5708L11.2297 12.973C11.3341 13.0566 11.4859 13.0431 11.574 12.9424L14.4439 9.66254C14.7166 9.35081 15.1905 9.31923 15.5022 9.59199Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3283 3.5638C12.4879 3.19032 11.5287 3.19032 10.6884 3.5638L5.69053 5.78506C4.50564 6.31168 3.75473 7.47527 3.82704 8.71672C4.00319 11.7409 4.52992 13.8325 5.53124 15.54C6.53382 17.2497 8.05558 18.6491 10.3646 20.2123C11.356 20.8835 12.6632 20.8844 13.6518 20.2135C15.9649 18.6438 17.4751 17.2311 18.4683 15.519C19.4612 13.8074 19.9799 11.7228 20.1716 8.7337C20.2519 7.4826 19.4991 6.30639 18.3063 5.77625L13.3283 3.5638ZM10.0791 2.19309C11.3073 1.64723 12.7093 1.64723 13.9375 2.19309L18.9155 4.40553C20.6341 5.16935 21.7923 6.90137 21.6686 8.82974C21.4691 11.9384 20.9205 14.281 19.7658 16.2717C18.6113 18.2617 16.8935 19.8264 14.4941 21.4547C12.9954 22.4717 11.0214 22.4684 9.52365 21.4544C7.13452 19.837 5.40319 18.287 4.23731 16.2988C3.07017 14.3085 2.51306 11.954 2.32958 8.80395C2.21791 6.88681 3.37498 5.17272 5.08133 4.41434L10.0791 2.19309Z"
        fill="currentcolor"
      />
    </svg>
  );
}
