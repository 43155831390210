export function Close() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.02094 5.02094C5.31383 4.72805 5.7887 4.72805 6.0816 5.02094L12 10.9393L17.9184 5.02102C18.2113 4.72813 18.6862 4.72813 18.9791 5.02102C19.272 5.31391 19.272 5.78879 18.9791 6.08168L13.0607 12L18.9791 17.9183C19.272 18.2112 19.272 18.6861 18.9791 18.979C18.6862 19.2719 18.2113 19.2719 17.9184 18.979L12 13.0607L6.0816 18.9791C5.7887 19.272 5.31383 19.272 5.02094 18.9791C4.72805 18.6862 4.72805 18.2113 5.02094 17.9184L10.9394 12L5.02094 6.0816C4.72805 5.78871 4.72805 5.31384 5.02094 5.02094Z"
        fill="#334156"
      />
    </svg>
  );
}
