export function ChevronDown() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentcolor"
        d="M4.23477 9.42787C4.50434 9.11338 4.97781 9.07696 5.29231 9.34653L12.0003 15.0963L18.7083 9.34653C19.0228 9.07696 19.4963 9.11338 19.7659 9.42787C20.0354 9.74237 19.999 10.2158 19.6845 10.4854L12.4884 16.6535C12.2075 16.8942 11.7931 16.8942 11.5122 16.6535L4.31612 10.4854C4.00162 10.2158 3.9652 9.74237 4.23477 9.42787Z"
      />
    </svg>
  );
}
