export function Lock() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 3.27112C10.3203 3.27112 8.95854 4.63287 8.95854 6.31267V8.88028H15.0417V6.31267C15.0417 4.63287 13.6799 3.27112 12.0001 3.27112ZM16.5417 8.94192V6.31267C16.5417 3.80444 14.5083 1.77112 12.0001 1.77112C9.49187 1.77112 7.45854 3.80444 7.45854 6.31267V8.94192C5.3072 9.29941 3.66699 11.1691 3.66699 13.4218V17.6873C3.66699 20.1956 5.70031 22.2289 8.20854 22.2289H15.7917C18.2999 22.2289 20.3332 20.1956 20.3332 17.6873V13.4218C20.3332 11.1691 18.693 9.29941 16.5417 8.94192ZM8.20854 10.3803C6.52874 10.3803 5.16699 11.742 5.16699 13.4218V17.6873C5.16699 19.3671 6.52874 20.7289 8.20854 20.7289H15.7917C17.4715 20.7289 18.8332 19.3671 18.8332 17.6873V13.4218C18.8332 11.742 17.4715 10.3803 15.7917 10.3803H8.20854ZM12.0001 13.1458C12.4143 13.1458 12.7501 13.4816 12.7501 13.8958V15.7916C12.7501 16.2058 12.4143 16.5416 12.0001 16.5416C11.5859 16.5416 11.2501 16.2058 11.2501 15.7916V13.8958C11.2501 13.4816 11.5859 13.1458 12.0001 13.1458Z"
        fill="currentcolor"
      />
    </svg>
  );
}
