import axios, { InternalAxiosRequestConfig } from "axios";
import { LocalStorage } from "../enums/LocalStorage";

const api = axios.create({
  baseURL: import.meta.env.VITE_API,
});

api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem(LocalStorage.ACCESS_TOKEN);

  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default api;
