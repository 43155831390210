import { Dollar } from "../../../../assets/icons/outline/Dollar";
import { Loading } from "../../../../components/Loading";
import { Parcelamento } from "../../../../services/renegociacao/types";
import { formatMoney } from "../../../../utils/formatters";
import {
  Container,
  LabelDebt,
  LabelDiscount,
  LabelPayment,
  Line,
  ValueDebt,
  ValueDiscount,
  ValuePayment,
} from "./styles";

interface Props {
  parcela?: Parcelamento;
  valorDivida?: number;
  expiresAt?: string;
}

export function SummaryAgreement({ parcela, valorDivida, expiresAt }: Props) {
  const discountPercentage =
    parcela && !!valorDivida
      ? Math.abs(Math.ceil((parcela.valorDiferenca / valorDivida) * 100))
      : 0;
  const showDiscount = !!parcela && valorDivida && parcela.valorDiferenca < 0;

  if (parcela) {
    return (
      <Container>
        {showDiscount && (
          <>
            <Line>
              <LabelDebt>Valor da dívida</LabelDebt>
              <ValueDebt>{formatMoney(valorDivida)}</ValueDebt>
            </Line>
            <Line>
              <LabelDiscount>Desconto {discountPercentage}%</LabelDiscount>
              <ValueDiscount>
                {formatMoney(Math.abs(parcela.valorDiferenca))}
              </ValueDiscount>
            </Line>
            <hr />
          </>
        )}
        <Line>
          <LabelPayment>
            <Dollar />
            Valor total à vista
          </LabelPayment>
          <ValuePayment>
            {!!valorDivida && formatMoney(parcela?.valorTotal)}
          </ValuePayment>
        </Line>
        {!!expiresAt && (
          <Line>
            <LabelDebt>Data de vencimento</LabelDebt>
            <ValueDebt>{new Date(expiresAt).toLocaleDateString()}</ValueDebt>
          </Line>
        )}
      </Container>
    );
  }

  return <Loading />;
}
