import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Accordion from "@radix-ui/react-accordion";
import { ChevronDown } from "@havan/paladinos-react-icons";
import { Button } from "@/components/Button";
import liberdade from "@/assets/images/Negociar-Divida-Liberdade.webp";
import { Pergunta } from "@/services/faq/types";
import { Faq } from "@/services/faq";
import styles from "./often_doubts.module.css";

export function OftenDoubts() {
  const [faq, setFaq] = useState<Pergunta[]>([]);

  useEffect(() => {
    Faq.perguntas().then((response) => setFaq(response.data.result));
  }, []);

  return (
    <section id="duvidas" className={styles.wrapper}>
      <h2>Dúvidas frequentes</h2>
      <div className={styles.content}>
        <img
          src={liberdade}
          alt="Ilustração da estátua da Liberdade acessando o portal de negociação Havan pelo celular"
        />
        <Accordion.Root
          className={styles.accordion_root}
          type="single"
          collapsible
        >
          {faq.map((pergunta) => (
            <Accordion.Item
              key={pergunta.id}
              value={pergunta.id.toString()}
              className={styles.accordion_item}
            >
              <Accordion.Trigger className={styles.accordion_trigger}>
                <h3>{pergunta.pergunta}</h3>
                <span>
                  <ChevronDown />
                </span>
              </Accordion.Trigger>
              <Accordion.Content className={styles.accordion_content} asChild>
                <div dangerouslySetInnerHTML={{ __html: pergunta.resposta }} />
              </Accordion.Content>
            </Accordion.Item>
          ))}
          <div className={styles.not_found}>
            <div className={styles.not_found_content}>
              <h2>Não encontrou sua dúvida?</h2>
              <p>
                Entre em contato em nossos canais de atendimento, estamos à
                disposição para ajudá-lo
              </p>
            </div>
            <Button
              as={Link}
              to="https://portalcliente.havan.com.br/atendimento?utm_source=portalnegociacao&utm_medium=referral&utm_content=home"
              target="_blank"
            >
              Ir para os canais de atendimento
            </Button>
          </div>
        </Accordion.Root>
      </div>
    </section>
  );
}
