export function QrCodeApp() {
  return (
    <svg
      width="101"
      height="101"
      viewBox="0 0 101 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.4949 100H72.4952V95.9933H68.4888L68.5021 96.0033V91.9767H64.5191V87.9833H68.4755V84.02H60.496V99.9933H56.4963V91.9767H52.5032V83.9867L52.4932 83.9967H56.4696V79.9867H52.4932L52.5032 79.9967C52.5032 78.66 52.5032 77.3233 52.5032 75.9867L52.4932 75.9967H60.5127V79.9567H64.4924V63.9833H76.4916V59.9833C77.8282 59.9867 79.1648 59.99 80.5047 59.9933L80.4947 59.9833V63.9933H84.5044L84.4944 63.9833V84H76.4816V88.0033H72.5285V91.9567H80.5113V87.9833H92.5105V79.9933H96.5036L96.4936 79.9833V83.99H100.5V99.99H96.5003V95.9833H88.4941L88.5041 95.9967V92.02H84.4944V95.9967L84.5044 95.9833H76.4983V99.99L76.4949 100ZM68.5055 79.9667H80.468V68.0333H68.5055V79.9667Z"
        fill="currentcolor"
      />
      <path
        d="M40.504 20.0067C40.504 17.3367 40.504 14.6667 40.504 11.9967L40.4907 12.01H44.4671V4.01667H32.4979V0H48.4968V3.97H52.4965V0H60.496V4.02333H56.5196V8.01333H60.5127V32.0233H68.4688V36.0133H64.4991V44.0067H60.476V40.0067C57.7829 40.0067 55.1364 40.0067 52.4865 40.0067C52.4865 38.68 52.4865 37.3533 52.4865 35.9833H56.4696V32.0267H48.5201V39.9967H52.4999V51.97H48.4701V43.9833H44.4837V39.9933H40.4907L40.5007 40.0033C40.5007 38.6667 40.5007 37.33 40.5007 35.9933L40.4907 36.0033H44.4904V27.9933H48.4668V24.0067H44.4904C44.4904 22.68 44.4904 21.3533 44.4904 19.9933H40.4907L40.504 20.0033V20.0067ZM52.5265 24.02V27.97H56.4729V24.02H52.5265ZM52.4665 11.9833V8.03333H48.5168V11.9833H52.4665Z"
        fill="currentcolor"
      />
      <path
        d="M0.5 48C1.83324 48 3.16982 48.0033 4.50307 48.0067L4.48973 47.9933V51.97H12.4925V48.0167H20.4787V52.0033H24.5017L24.4884 51.99V55.9667H32.4812V52.0033H36.5009L36.4909 51.99V56H40.5007L40.4907 55.99V60.0167H44.4737V64.0067H36.5309V68.01H48.5068V71.99H52.5032C52.4999 73.3267 52.4965 74.6633 52.4899 76L52.4999 75.99H48.5235V80H52.4999L52.4899 79.99C52.4899 81.3267 52.4899 82.6633 52.4899 84L52.4999 83.99H48.4801V91.99H44.4904L44.5004 92.0033V87.9933H40.4907L40.5007 88.0033C40.4973 86.6667 40.494 85.33 40.4907 83.99H44.4637V72.02H40.4873V75.9667H36.4809V71.99H32.4745V67.9867H20.4853V59.9933H16.4889L16.5023 60.0067V56.0333H8.4728V60.02H4.4864V68.0033H0.5C0.5 61.3333 0.5 54.6667 0.5 48ZM24.5151 63.9667H28.4781V60.0267H24.5151V63.9667Z"
        fill="currentcolor"
      />
      <path
        d="M100.493 28H72.4952V0H100.493V28ZM96.477 4.03H76.5283V23.9767H96.477V4.03Z"
        fill="currentcolor"
      />
      <path
        d="M0.5 72H28.4981V100H0.5V72ZM24.4684 95.98V76.03H4.52307V95.98H24.4717H24.4684Z"
        fill="currentcolor"
      />
      <path
        d="M28.4981 0V28H0.5V0H28.4981ZM4.52973 4.02V23.97H24.4784V4.02H4.52973Z"
        fill="currentcolor"
      />
      <path
        d="M100.493 56V64C99.1601 64 97.8235 64.0033 96.4903 64.0067C96.4903 62.68 96.4903 61.3567 96.4903 60.0267H92.5239V63.9933H96.5003C96.4969 66.6633 96.4903 69.3333 96.4869 72.0033L96.4969 71.9933H88.4775V63.9933H84.4844L84.4944 64.0033C84.4944 62.6767 84.4944 61.35 84.4944 59.9933H80.4847L80.4947 60.0033C80.4947 57.3333 80.4947 54.6633 80.4947 51.9933L80.4813 52.0033H84.5077V55.9733H92.4972V52.0033H96.4936L96.4836 51.9933V56H100.493Z"
        fill="currentcolor"
      />
      <path
        d="M76.4916 36.0067C81.5379 36.0067 86.5876 36.0033 91.6339 36C93.2572 36 94.877 35.9967 96.5003 35.9933C96.5003 37.33 96.5003 38.6667 96.5003 40.0033C93.8438 40.0033 91.184 40.0033 88.5208 40.0033V43.97H96.4869V39.9933C97.8202 39.9933 99.1568 39.9967 100.49 40V52C99.1534 52 97.8202 51.9967 96.4836 51.9933L96.4936 52.0033V47.9933H80.4847L80.4947 48.0033V39.9933H76.4849L76.4983 40.0033C76.4983 38.6667 76.4983 37.33 76.4983 35.9933L76.4849 36.0067H76.4916Z"
        fill="currentcolor"
      />
      <path
        d="M44.4904 91.9933C44.4904 94.6633 44.4937 97.33 44.4971 100H40.4973V96.03H36.4976V100H32.4979V92.0133H36.4909V87.9933C37.8275 87.9967 39.1641 88 40.504 88.0033L40.494 87.9933V92.0033H44.5037L44.4937 91.99L44.4904 91.9933Z"
        fill="currentcolor"
      />
      <path
        d="M4.5164 35.9933V40H0.5V32H8.4928V36.0067L8.50613 35.9933H4.5164Z"
        fill="currentcolor"
      />
      <path
        d="M100.493 80C99.1568 80 97.8235 79.9967 96.4869 79.9933L96.4969 80.0033V71.9933L96.4869 72.0033C97.8235 72.0033 99.1568 72 100.493 71.9967V80Z"
        fill="currentcolor"
      />
      <path d="M48.4968 100V96.03H52.4965V100H48.4968Z" fill="currentcolor" />
      <path
        d="M64.4957 96.0067H68.5021L68.4888 95.9967C68.4888 97.3333 68.4921 98.6667 68.4955 100.003H64.4957V96.0067Z"
        fill="currentcolor"
      />
      <path
        d="M100.493 36C99.1568 36 97.8235 36.0033 96.4869 36.0067V32H100.493V36Z"
        fill="currentcolor"
      />
      <path
        d="M88.4875 95.9933C88.4875 97.33 88.4908 98.6633 88.4941 100H84.4944C84.4944 98.6633 84.4977 97.33 84.5011 95.9933L84.4911 96.0067C85.8277 96.0067 87.1642 96.0067 88.5008 96.0067L88.4908 95.9933H88.4875Z"
        fill="currentcolor"
      />
      <path
        d="M36.5043 36.0067V32.0167H32.5245V24.0067H36.5043L36.4943 23.9933V27.9933H40.5073C40.504 30.6633 40.5007 33.3333 40.494 36.0033L40.504 35.9933C39.1674 35.9933 37.8309 35.9933 36.4943 35.9933L36.5076 36.0033L36.5043 36.0067Z"
        fill="currentcolor"
      />
      <path
        d="M36.5043 52.0067C36.5043 50.67 36.5043 49.3333 36.5043 47.9933L36.4909 48.0033H44.4671V55.9933H40.4907L40.5007 56.0033V51.9933H36.4909L36.5009 52.0067H36.5043Z"
        fill="currentcolor"
      />
      <path
        d="M28.4948 36.0067C31.1646 36.0067 33.8344 36.0067 36.5043 36.0067L36.4909 35.9967V40.0067L36.5009 39.9933C33.8311 39.9933 31.1613 39.9933 28.4915 39.9933L28.5048 40.0067C28.5048 38.67 28.5048 37.3333 28.5048 35.9967L28.4915 36.01L28.4948 36.0067Z"
        fill="currentcolor"
      />
      <path
        d="M12.5225 36.02V32.02H20.4653V36.01C17.8222 36.01 15.1657 36.01 12.5092 36.01L12.5225 36.02Z"
        fill="currentcolor"
      />
      <path
        d="M36.5043 44.0067C36.5043 42.67 36.5043 41.3333 36.5043 39.9933L36.4943 40.0067C37.8308 40.0067 39.1674 40.0067 40.504 40.0067L40.494 39.9967V43.9967H36.4943L36.5076 44.0067H36.5043Z"
        fill="currentcolor"
      />
      <path
        d="M28.4948 39.9933V43.97H24.5284V40.0067C25.855 40.0067 27.1816 40.0067 28.5081 40.0067L28.4948 39.9933Z"
        fill="currentcolor"
      />
      <path
        d="M12.4792 40.0233H16.4789V43.9667H12.4925C12.4925 42.6567 12.4925 41.3333 12.4925 40.01L12.4825 40.0233H12.4792Z"
        fill="currentcolor"
      />
      <path
        d="M24.5017 48.03H28.4681V51.9967C27.1416 51.9967 25.815 51.9967 24.4884 51.9967L24.5017 52.01V48.03Z"
        fill="currentcolor"
      />
      <path
        d="M28.5048 35.9967L24.5284 35.9967V32.03H28.4915C28.4915 33.36 28.4915 34.6833 28.4915 36.01L28.5048 35.9967Z"
        fill="currentcolor"
      />
      <path
        d="M36.5043 47.9933L32.5279 47.9933V44.0033H36.5043L36.4909 43.9933C36.4909 45.33 36.4909 46.6667 36.4909 48.0033L36.5043 47.9933Z"
        fill="currentcolor"
      />
      <path
        d="M36.5043 24.0067V20.0067C37.8508 20.0067 39.1774 20.0067 40.504 20.0067L40.4907 19.9967C40.494 21.3333 40.4973 22.67 40.504 24.0067C39.1674 24.0033 37.8309 24 36.4909 23.9967L36.5009 24.01L36.5043 24.0067Z"
        fill="currentcolor"
      />
      <path
        d="M4.50307 48.0067L4.50307 44.03H8.46947V47.9967C7.14289 47.9967 5.81631 47.9967 4.48973 47.9967L4.50307 48.01L4.50307 48.0067Z"
        fill="currentcolor"
      />
      <path
        d="M40.504 11.9967L36.5276 11.9967V8.03H40.4907C40.4907 9.36 40.4907 10.6833 40.4907 12.01L40.504 11.9967Z"
        fill="currentcolor"
      />
      <path
        d="M8.4928 36.0067C9.83605 36.01 11.1793 36.0167 12.5225 36.02L12.5092 36.01C12.4992 37.35 12.4892 38.6867 12.4792 40.0267L12.4892 40.0133C11.176 40.0133 9.86271 40.0133 8.5028 40.0133C8.5028 38.6467 8.5028 37.32 8.5028 35.9967L8.48947 36.01L8.4928 36.0067Z"
        fill="currentcolor"
      />
      <path
        d="M80.5013 51.9933H72.4885L72.4985 52.0033V43.9933H68.4888L68.5022 44.0033V40.0033C71.182 40.0033 73.8418 40.0033 76.5016 40.0033L76.4883 39.9933V48.0033H80.498L80.488 47.9933C80.488 49.33 80.488 50.6667 80.488 52.0033L80.5013 51.9933Z"
        fill="currentcolor"
      />
      <path
        d="M68.4721 19.98H64.5224V12.0233H68.4721V19.98Z"
        fill="currentcolor"
      />
      <path
        d="M76.5016 35.9967H72.5252V32.03H76.4883C76.4883 33.36 76.4883 34.6833 76.4883 36.01L76.5016 35.9967Z"
        fill="currentcolor"
      />
      <path
        d="M68.4755 24.03V27.9767H64.5291V24.03H68.4755Z"
        fill="currentcolor"
      />
      <path
        d="M64.5024 48.0067C64.4991 46.67 64.4957 45.3333 64.4891 43.9967C65.8257 44 67.1622 44.0033 68.5021 44.0067L68.4888 43.9967V47.9967H64.4891L64.5024 48.0067Z"
        fill="currentcolor"
      />
      <path
        d="M64.5257 4.02H68.4721V7.96667H64.5257V4.02Z"
        fill="currentcolor"
      />
      <path
        d="M40.504 84.0067H36.5043V88.0067H32.5279V80.03H40.504V84.0067Z"
        fill="currentcolor"
      />
      <path
        d="M16.4956 59.9933V67.97H8.52613V64.01H12.4825V60.0033C13.8524 60.0033 15.179 60.0033 16.5056 60.0033L16.4923 59.99L16.4956 59.9933Z"
        fill="currentcolor"
      />
      <path
        d="M56.4896 56.0067V52.0167H60.4827V48.0067C61.8493 48.0067 63.1758 48.0067 64.5024 48.0067L64.4891 47.9967L64.4891 52.0067H72.4985L72.4885 51.9933L72.4885 55.9833H76.4983V60.0033H64.4991V64.0033H60.5093V56.0033H56.4896V56.0067Z"
        fill="currentcolor"
      />
      <path
        d="M52.4899 72.0067C52.4899 69.3367 52.4899 66.6667 52.4899 63.9967H56.4663V72.0067H52.4899Z"
        fill="currentcolor"
      />
      <path
        d="M52.4899 60.0067V55.9967H56.4996V60.0067H52.4899Z"
        fill="currentcolor"
      />
      <path
        d="M52.5032 64.0067H48.5268V59.9967H52.5032C52.5032 61.3333 52.5032 62.67 52.5032 64.0067Z"
        fill="currentcolor"
      />
      <path
        d="M72.5252 72.02H76.4716V75.9667H72.5252V72.02Z"
        fill="currentcolor"
      />
      <path
        d="M40.4907 28.0067C40.4907 26.67 40.4907 25.3333 40.4907 23.9967H44.5004C44.5004 25.3333 44.5004 26.67 44.5004 28.0067H40.4907Z"
        fill="currentcolor"
      />
      <path
        d="M80.5147 8.02333H92.4605V19.9733H80.5147V8.02333Z"
        fill="currentcolor"
      />
      <path
        d="M20.4787 80.03V91.98H8.5328V80.03H20.4787Z"
        fill="currentcolor"
      />
      <path
        d="M8.52947 8.01667H20.4753V19.9633H8.52947V8.01667Z"
        fill="currentcolor"
      />
    </svg>
  );
}
