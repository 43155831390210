export function X() {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5222 7.05719C26.0429 7.57789 26.0429 8.42211 25.5222 8.94281L9.52224 24.9428C9.00154 25.4635 8.15732 25.4635 7.63662 24.9428C7.11592 24.4221 7.11592 23.5779 7.63662 23.0572L23.6366 7.05719C24.1573 6.53649 25.0015 6.53649 25.5222 7.05719Z"
        fill="#076BEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63662 7.05719C8.15732 6.53649 9.00154 6.53649 9.52224 7.05719L25.5222 23.0572C26.0429 23.5779 26.0429 24.4221 25.5222 24.9428C25.0015 25.4635 24.1573 25.4635 23.6366 24.9428L7.63662 8.94281C7.11592 8.42211 7.11592 7.57789 7.63662 7.05719Z"
        fill="#076BEE"
      />
    </svg>
  );
}
