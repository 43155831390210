import { cloneElement, PropsWithChildren, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { Alert } from ".";
import { AlertContext } from "./context";
import { Alerts } from "./styles";
import { AddAlertProps, AlertProps } from "./types";

export function AlertProvider({ children }: PropsWithChildren): JSX.Element {
  const [alerts, setAlerts] = useState<AlertProps[]>([]);

  function addAlert(alert: AddAlertProps) {
    setAlerts((state) => [...state, { ...alert, id: crypto.randomUUID() }]);
  }

  function alert(title: string, description: string) {
    addAlert({ title, description, variant: "alert" });
  }

  function error(title: string, description: string) {
    addAlert({ title, description, variant: "error" });
  }

  function info(title: string, description: string) {
    addAlert({ title, description, variant: "info" });
  }

  function success(title: string, description: string) {
    addAlert({ title, description, variant: "success" });
  }

  function remove(id: string) {
    setAlerts((state) => state.filter((alert) => alert.id !== id));
  }

  const providerProps = useMemo(
    () => ({ alert, error, info, success, remove }),
    []
  );

  return (
    <AlertContext.Provider value={providerProps}>
      {children}
      {createPortal(
        <Alerts>
          {alerts.map((alert) =>
            cloneElement(<Alert {...alert} />, { key: alert.id })
          )}
        </Alerts>,
        document.getElementById("alerts")!
      )}
    </AlertContext.Provider>
  );
}
