import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { Container, Text } from "./styles";
import logoLabs from "../../assets/logos/logoLabs.svg";

export function Footer() {
  const navigate = useNavigate();

  return (
    <Container>
      <div>
        <Button
          variant="hiperlink"
          onClick={() => navigate("politica-de-privacidade")}
        >
          Políticas de privacidade Havan
        </Button>
        <Text>
          HAVAN S.A. | CNPJ: 79.379.491.0008-50 | Rod. Antônio Heil, 250 -
          Centro II, Brusque - SC, 88353-100
        </Text>
        <Text>© {new Date().getFullYear()} - Todos os direitos reservados</Text>
      </div>
      <img src={logoLabs} height={40} alt="Havan Labs" />
    </Container>
  );
}
