import { fromUnixTime, isPast, subMinutes } from "date-fns";
import { LocalStorage } from "../enums/LocalStorage";

/**
 *
 * @param exp @number
 * @returns
 */
export function isTokenExpired(exp: number) {
  return isPast(subMinutes(fromUnixTime(exp), 1));
}

export function clearLocalStorage() {
  localStorage.removeItem(LocalStorage.USER_DATA);
  localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
}
