import { FormEvent, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { parse } from "date-fns";
import { Button } from "@/components/Button";
import { Input } from "@/components/Input";
import { Checkbox } from "@/components/Checkbox";
import { useAuth } from "@/auth/hook";
import {
  formatBirthdate,
  formatCpf,
  isBirthdateValid,
  isCpfValid,
} from "@/utils/formatters";
import styles from "./styles.module.css";

export function FormLogin() {
  const navigate = useNavigate();
  const { auth, attempt } = useAuth();
  const [logged, setLogged] = useState<boolean>();
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [cpf, setCpf] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const isCpfInputSuccess = cpf.length > 0 && isCpfValid(cpf);
  const isCpfInputError = cpf.length > 0 && !isCpfInputSuccess;
  const isInputBirthdateValid =
    birthdate.length === 10 && isBirthdateValid(birthdate);
  const isInputBirthdateError = birthdate.length > 0 && !isInputBirthdateValid;
  const { state } = useLocation();

  async function fetchUser(event: FormEvent) {
    event.preventDefault();

    if (isCpfInputSuccess && isInputBirthdateValid) {
      const isoBirthdate = parse(
        birthdate,
        "dd/MM/yyyy",
        new Date()
      ).toISOString();

      const logged = await attempt({ cpf, birthdate: isoBirthdate });
      setLogged(logged);
    }
  }

  useEffect(() => {
    if (logged) {
      navigate("negociar");
    }

    if (state?.politicas) {
      setAcceptedTerms(true);
      window.scroll({ top: 0 });
    }
  }, [logged, auth]);

  return (
    <div className={styles.wrapper}>
      <h1>Negociar dívida Havan</h1>
      <form onSubmit={fetchUser}>
        <Input
          label="CPF"
          name="cpf"
          value={cpf}
          isError={state?.politicas ? state.politicas : isCpfInputError}
          isSuccess={isCpfInputSuccess}
          onChange={(event) => setCpf(formatCpf(event.target.value))}
          maxLength={14}
          placeholder="000.000.000-00"
          helperText={state?.politicas ? "Informe o CPF" : ""}
        />
        <Input
          label="Data de nascimento"
          type="text"
          name="birthdate"
          value={birthdate}
          onChange={(event) =>
            setBirthdate(formatBirthdate(event.target.value))
          }
          isSuccess={isInputBirthdateValid}
          isError={state?.politicas ? state.politicas : isInputBirthdateError}
          helperText={state?.politicas ? "Informe a data de nascimento" : ""}
          placeholder="00/00/0000"
          maxLength={10}
        />

        <Checkbox
          onCheckedChange={(value: boolean) => setAcceptedTerms(value)}
          checked={acceptedTerms}
          className={styles.check_wrapper}
        >
          <p className={styles.check_terms}>
            Li e concordo com a{" "}
            <Link to="politica-de-privacidade">
              Política de privacidade Havan
            </Link>
          </p>
        </Checkbox>
        <Button id="login" type="submit" disabled={!acceptedTerms}>
          Ver o melhor acordo
        </Button>
      </form>
    </div>
  );
}
