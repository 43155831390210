import { styled } from "../../styles";

export const Header = styled("h2", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 92,
  backgroundColor: "$primaryDark",
  fontWeight: "$extraBold",
  fontSize: "$xl",
  lineHeight: "44px",
  color: "$neutralLighter",

  "@max_width_480": {
    height: 80,
    fontSize: "$lg",
    lineHeight: "33px",
  },
});
