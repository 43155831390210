import * as Dialog from "@radix-ui/react-dialog";
import { keyframes } from "@stitches/react";
import { styled } from "../../styles";

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

export const ModalOverlay = styled(Dialog.Overlay, {
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  position: "fixed",
  inset: 0,
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  zIndex: 100,
});

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

export const ModalContent = styled("div", {
  backgroundColor: "$neutralLighter",
  borderRadius: 6,
  boxShadow:
    "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  position: "fixed",
  width: "90vw",

  maxHeight: "85vh",

  boxSizing: "border-box",
  overflowY: "auto",
  "&:focus": { outline: "none" },

  "@min_width_481": {
    maxWidth: "450px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 48,
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,

    "&::-webkit-scrollbar": {
      width: 4,
    },

    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 4px $primaryLight",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "$primaryMedium",
      borderRadius: 2,

      "&:hover": {
        backgroundColor: "$primaryDark",
      },
    },
  },

  "@max_width_480": {
    position: "fixed",
    bottom: 0,
    height: "100%",
    width: "100%",
    borderRadius: "16px 16px 0px 0px",
    paddingBottom: 40,
    paddingTop: 24,
  },
});

export const ModalIconClose = styled("button", {
  all: "unset",
  fontFamily: "inherit",
  borderRadius: "100%",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  color: "$neutralDark",
  position: "absolute",

  top: 48,
  right: 48,

  "&:hover": { cursor: "pointer" },

  "@max_width_480": {
    top: 24,
    right: 16,
  },
});

export const ModalTitle = styled(Dialog.Title, {
  fontWeight: "$extraBold",
  fontSize: "22px",
  lineHeight: "30px",
  color: "$neutralDarkest",
  textAlign: "center",
});
