import { isValid, parse } from "date-fns";

const REGEX_INVALID_CPF = /^(\d)\1{10}$/;
const REGEX_CLEAR_PONCTUATION_CPF = /(\.)|(\-)/g;
const REGEX_CLEAR_PONCTUATION_BIRTHDATE = /(\/)/g;
const REGEX_CLEAR_PONCTUATION_PHONE = /(\()|(\))/g;

export function formatMoney(value: number) {
  return Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
}

export function formatBirthdate(value: string) {
  return value
    .replaceAll(REGEX_CLEAR_PONCTUATION_BIRTHDATE, "")
    .replace(/^(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2");
}

/**
 *
 * @param value format date dd/MM/YYYY
 * @returns @boolean
 */
export function isBirthdateValid(value: string) {
  return isValid(parse(value, "dd/MM/yyyy", new Date())) || value.length === 0;
}

export function formatCpf(value: string) {
  return value
    .replaceAll(REGEX_CLEAR_PONCTUATION_CPF, "")
    .replace(/^(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
}

export function extractDDDPhoneNumber(value: string) {
  const [ddd, numeroCelular] = value
    .replaceAll(REGEX_CLEAR_PONCTUATION_PHONE, "")
    .split(" ");

  return { ddd, numeroCelular };
}

export function formatPhoneNumber(value: string) {
  return value.replace(/^(\d{2})(\d)/, "($1) $2");
}

export function phoneNumberIsValid(value: string) {
  return !!value.match(/\(\d{2}\)\s\d{9}/);
}

function calculateRemainderCPF(sum: number) {
  const remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    return 0;
  }

  return remainder;
}

export function isCpfValid(cpf: string): boolean {
  if (cpf.length === 0) {
    return true;
  }

  if (cpf.length < 14) {
    return false;
  }

  let sum = 0;
  let remainder = 0;
  const strCPF = cpf.replaceAll(REGEX_CLEAR_PONCTUATION_CPF, "");

  if (cpf.match(REGEX_INVALID_CPF)) {
    return false;
  }

  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
  }

  remainder = calculateRemainderCPF(sum);

  if (remainder !== parseInt(strCPF.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i += 1) {
    sum += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
  }

  remainder = calculateRemainderCPF(sum);

  if (remainder !== parseInt(strCPF.substring(10, 11), 10)) {
    return false;
  }

  return true;
}
