export function Download() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.35168 11.9697C8.05878 12.2626 8.05878 12.7374 8.35168 13.0303L11.5486 16.2273C11.8415 16.5202 12.3164 16.5202 12.6093 16.2273L15.8062 13.0303C16.0991 12.7374 16.0991 12.2626 15.8062 11.9697C15.5133 11.6768 15.0384 11.6768 14.7456 11.9697L12.8289 13.8863L12.8289 2.90918C12.8289 2.49497 12.4932 2.15918 12.0789 2.15918C11.6647 2.15918 11.3289 2.49497 11.3289 2.90918L11.3289 13.8863L9.41234 11.9697C9.11944 11.6768 8.64457 11.6768 8.35168 11.9697Z"
        fill="#0C3BDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.48828 22.8408C3.86493 22.8408 1.73828 20.7142 1.73828 18.0908L1.73828 13.3031C1.73828 10.6797 3.86493 8.55306 6.48828 8.55306H7.28369C7.6979 8.55306 8.03369 8.88885 8.03369 9.30306C8.03369 9.71727 7.6979 10.0531 7.28369 10.0531H6.48828C4.69336 10.0531 3.23828 11.5081 3.23828 13.3031L3.23828 18.0908C3.23828 19.8857 4.69336 21.3408 6.48828 21.3408H17.6699C19.4648 21.3408 20.9199 19.8857 20.9199 18.0908V13.3031C20.9199 11.5081 19.4648 10.0531 17.6699 10.0531H16.8745C16.4603 10.0531 16.1245 9.71727 16.1245 9.30306C16.1245 8.88885 16.4603 8.55306 16.8745 8.55306H17.6699C20.2933 8.55306 22.4199 10.6797 22.4199 13.3031V18.0908C22.4199 20.7142 20.2933 22.8408 17.6699 22.8408H6.48828Z"
        fill="#0C3BDD"
      />
    </svg>
  );
}
