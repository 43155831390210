import { styled } from "../../styles";

export const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: 16,
  marginBottom: 40,
});

export const StepAction = styled("button", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 8,
  border: "none",
  size: 40,
  backgroundColor: "$primaryLighter",
  color: "$primaryMedium",

  svg: {
    size: 24,
  },

  "&:hover": {
    backgroundColor: "$primaryLight",
    cursor: "pointer",
  },
});

export const StepInfo = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 8,
});

export const Header = styled("header", {
  display: "flex",
  justifyContent: "space-between",
  fontSize: "$xxs",
  lineHeight: "19px",
  color: "$neutralDarkest",
});

export const Title = styled("p", {
  fontWeight: "$bold",
});

export const Progress = styled("div", {
  width: "100%",
  height: 6,
  borderRadius: 10,
  backgroundColor: "$primaryLight",
});

export const ProgressValue = styled("div", {
  height: "100%",
  borderRadius: 10,
  backgroundColor: "$primaryMedium",
  width: "$$percentage",
});
