import { Link as RouterLink } from "react-router-dom";
import { styled } from "../../styles";

export const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  position: "relative",
  boxSizing: "border-box",

  "& + footer": {
    position: "relative",
    zIndex: 1,
  },

  "@max_width_768": {},

  "@min_width_769": {
    justifyContent: "space-between",
  },
});

export const Content = styled("div", {
  maxWidth: 1160,
  width: "100%",
  margin: "auto",

  "@max_width_480": {
    padding: 16,
    display: "flex",
    flexDirection: "column",
  },
});

export const Heading = styled("h1", {
  fontWeight: "$extraBold",
  fontSize: "$xl",
  lineHeight: "44px",
  color: "$neutralLighter",
  textTransform: "capitalize",

  "@max_width_480": {
    fontSize: "22px",
    lineHeight: "30px",
  },
});

export const NoDebt = styled("p", {
  marginTop: 16,
  color: "$neutralLighter",
  lineHeight: "22px",
});

export const NoDebtDescription = styled("p", {
  marginTop: 16,
  padding: 8,
  color: "$neutralLighter",
  backgroundColor: "$primaryMedium",
  borderRadius: 8,
  lineHeight: "22px",
});

export const HeadingDeal = styled("h1", {
  fontWeight: "$extraBold",
  fontSize: "$lg",
  lineHeight: "33px",
  color: "$neutralLighter",
  marginBottom: 16,

  "@max_width_480": {
    fontSize: "$sm",
    lineHeight: "25px",
  },

  "@min_width_1201": {
    paddingRight: 32,
  },
});

export const BlueCover = styled("div", {
  backgroundColor: "$primaryDark",
  display: "flex",
  width: "100%",
  zIndex: 0,
  boxSizing: "border-box",

  "@max_width_768": {
    paddingBottom: 160,
  },

  "@min_width_769": {
    height: 620,
    marginBottom: 82,
    paddingBottom: 72,
    padding: 16,
  },
});

export const TextCoverContainer = styled("div", {
  alignSelf: "start",

  "@min_width_481": {
    width: "100%",
    maxWidth: 378,
  },

  "@max_width_768": {
    margin: "auto",
    marginTop: 48,
  },

  "& > svg": {
    color: "$neutralLighter",

    "@max_width_768": {
      marginTop: 48,
    },

    "@min_width_769": {
      marginTop: 32,
    },
  },
});

export const Description = styled("p", {
  fontSize: "$xxs",
  lineHeight: "19px",
  color: "$primaryLight",
});

export const ImageContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  maxWidth: 1160,
  width: "100%",
  marginBottom: 40,
  margin: "auto",
  position: "relative",
  boxSizing: "border-box",
  padding: 16,
  minHeight: "calc(100vh - 828px)",

  "@max_width_768": {
    zIndex: 1,
    marginTop: -120,
  },
});

export const Image = styled("img", {
  filter: "drop-shadow(-25px 25px 30px rgba(0, 0, 0, 0.6))",

  "@max_width_768": {
    zIndex: 1,
    maxHeight: 350,
    margin: "auto",
    marginBottom: 40,
  },

  "@min_width_769": {
    position: "absolute",

    bottom: -50,
    right: 0,
    maxHeight: 600,
  },

  "@min_width_1025": {
    maxHeight: 750,
    bottom: -60,
  },

  "@min_width_1201": {
    maxHeight: 750,
  },

  "@min_width_1410": {
    maxHeight: 750,
  },
});

export const DownloadAppContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: 16,
  height: 100,
  width: 273,
  marginTop: 24,

  svg: {
    size: 100,
    flexShrink: 0,
    color: "$neutralLighter",
  },
});

export const QrCodeAppContainer = styled("div", {
  margin: "24px auto",
  height: 100,
  width: 273,

  display: "flex",
  alignItems: "center",
  gap: 16,

  svg: {
    size: 100,
    flexShrink: 0,
    color: "$neutralLighter",
  },
});

export const QrCodeInfo = styled("div", {
  fontSize: "$xxs",
  lineHeight: "19px",
  color: "$neutralLighter",
  textAlign: "start",
});

export const Link = styled(RouterLink, {
  fontWeight: "$bold",
  color: "$neutralLighter",

  "&:visited": {
    color: "$neutralLighter",
  },
});
