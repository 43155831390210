import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { Heading } from "../../components/Heading";
import { Text } from "../../components/Text";
import { styled } from "../../styles";

export const Header = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 101,
  backgroundColor: "$primaryDark",
  color: "$neutralLighter",

  "@max_width_480": {
    textAlign: "center",
    padding: "0 $4",
  },
});

export const PoliticaContainer = styled("div", {
  margin: "auto",
  marginTop: 24,
  padding: "$6 $10",
  backgroundColor: "$neutralLighter",
  maxWidth: 868,
  boxSizing: "border-box",
  border: "1px solid $primaryLight",
  borderRadius: 8,
  marginBottom: 32,

  [`${Heading}`]: {
    margin: "auto",
  },

  [`& ${Text}`]: {
    color: "$neutralDark",
    textAlign: "justify",
  },

  ul: {
    paddingLeft: 40,
    color: "$neutralDark",
  },
});

export const HeadingWrapper = styled("div", {
  textAlign: "center",
  marginBottom: 12,

  [`${Heading}`]: {
    fontWeight: "$extraBold",
    color: "$neutralDarkest",
  },
});

export const FooterPoliticasWrapper = styled("div", {
  marginTop: 40,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 16,
  textAlign: "center",

  [`${Button}`]: {
    width: "100%",
  },
});

export const TextLeuAcordo = styled(Text, {
  fontWeight: 600,

  span: {
    color: "$primaryDark",
  },
});

export const LinkForm = styled(Link, {
  "&:active": {
    color: "$primaryDarkest",
  },
});
