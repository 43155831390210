import { Container, Content, NavLink } from "./styles";
import logo from "../../assets/logos/logo.svg";
import logoLettering from "../../assets/logos/logo-lettering.svg";
import { ArrowLeft } from "../../assets/icons/outline/ArrowLeft";
import {
  ImageLogo,
  ImageLogoLettering,
} from "../../pages/LandingPage/Header/styles";
import { ModalSignOut } from "./ModalSignOut";
import { useLocation } from "react-router-dom";

export function Header() {
  const { pathname } = useLocation();

  return (
    <Container>
      <Content>
        <ImageLogo src={logo} alt="Logo" height={60} />
        <ImageLogoLettering src={logoLettering} />

        {pathname === "/negociar" ? (
          <NavLink to="/">
            <ArrowLeft /> Voltar
          </NavLink>
        ) : (
          <ModalSignOut />
        )}
      </Content>
    </Container>
  );
}
