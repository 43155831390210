import { styled } from "../../styles";

export const Button = styled("button", {
  height: 56,
  borderRadius: 8,
  fontSize: "$xs",
  padding: "17px $8",
  fontFamily: "$default",
  fontWeight: "$bold",
  lineHeight: "22px",
  boxSizing: "border-box",

  "&:hover": {
    cursor: "pointer",
  },

  "&:disabled": {
    cursor: "not-allowed",
  },

  variants: {
    variant: {
      primary: {
        backgroundColor: "$primaryDark",
        color: "$neutralLighter",
        border: "none",

        "&:hover": {
          backgroundColor: "$primaryMedium",
        },

        "&:disabled": {
          backgroundColor: "$neutralMedium",
        },
      },
      secondary: {
        padding: "11.5px 32px",
        borderWidth: 1,
        borderStyle: "solid",
        backgroundColor: "transparent",
      },
      hiperlink: {
        border: "none",
        color: "$primaryDark",
        backgroundColor: "transparent",

        "&:hover": {
          color: "$primaryMedium",
        },

        "&:disabled": {
          color: "$neutralMedium",
        },
      },
    },
    color: {
      default: {},
      danger: {},
    },
  },
  compoundVariants: [
    {
      variant: "secondary",
      color: "default",
      css: {
        borderColor: "$primaryDark",
        color: "$primaryDark",

        "&:hover": {
          color: "$primaryMedium",
          borderColor: "$primaryMedium",
        },

        "&:disabled": {
          color: "$neutralMedium",
          borderColor: "$neutralMedium",
        },
      },
    },
    {
      variant: "secondary",
      color: "danger",
      css: {
        borderColor: "$plusTwoDark",
        color: "$plusTwoDark",
      },
    },
  ],
  defaultVariants: {
    variant: "primary",
    color: "default",
  },
});

Button.displayName = "Button";
