export function Home(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0364 2.96567C11.1145 1.86593 12.8856 1.86593 13.9638 2.96567L21.619 10.7741C21.909 11.0698 21.9043 11.5447 21.6085 11.8347C21.3128 12.1246 20.8379 12.1199 20.5479 11.8242L19.6962 10.9554V19.1092C19.6962 20.628 18.465 21.8592 16.9462 21.8592H7.0539C5.53512 21.8592 4.3039 20.628 4.3039 19.1092V10.9554L3.45218 11.8242C3.1622 12.1199 2.68735 12.1246 2.39157 11.8347C2.09579 11.5447 2.09109 11.0698 2.38107 10.7741L10.0364 2.96567ZM5.8039 9.42541V19.1092C5.8039 19.7996 6.36355 20.3592 7.0539 20.3592H16.9462C17.6366 20.3592 18.1962 19.7996 18.1962 19.1092V9.42541L12.8926 4.01578C12.4026 3.5159 11.5975 3.5159 11.1075 4.01578L5.8039 9.42541Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49402 15.814C8.49402 14.2952 9.72524 13.064 11.244 13.064H12.7562C14.275 13.064 15.5062 14.2952 15.5062 15.814V21.1636H14.0062V15.814C14.0062 15.1236 13.4466 14.564 12.7562 14.564H11.244C10.5537 14.564 9.99402 15.1236 9.99402 15.814V21.1636H8.49402V15.814Z"
        fill="currentcolor"
      />
    </svg>
  );
}
