import { Button } from "../../../components/Button";
import { styled } from "../../../styles";

export const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  margin: "auto",
  marginTop: 32,
  textAlign: "center",
  paddingLeft: 16,
  paddingRight: 16,
  boxSizing: "border-box",

  "@max_width_480": {
    marginBottom: 24,
  },

  "@min_width_481": {
    width: "100%",
    maxWidth: 624,
    marginBottom: 40,
    minHeight: "calc(100vh - 100px - 128px)",
  },

  "@min_width_769": {
    padding: 0,
  },
});

export const NavigationButtons = styled("div", {
  marginTop: 32,
  display: "flex",
  flexDirection: "column",
  gap: 16,
});

export const ButtonQueroParcelar = styled(Button, {
  margin: "auto",
  boxSizing: "border-box",
  width: "100%",
});
