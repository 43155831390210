export const fontSizes = {
  xxxs: "0.75rem", // 12px
  xxs: "0.875rem", // 14px
  xs: "1rem", // 16px
  sm: "1.125rem", // 18px
  md: "1.25rem", // 20px
  lg: "1.5rem", // 24px
  xl: "2rem", // 32px
  xxl: "2.5rem", // 40px
  xxxl: "3rem", // 48px
  display: "4rem", // 64px
};
