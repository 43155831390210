import { Hero } from "./Hero";
import { Deal } from "./Deal";
import { WhyNegociate } from "./WhyNegociate";
import { OftenDoubts } from "./OftenDoubts";
import { Header } from "./Header";
import { useEffect } from "react";
import { PAGE_TITLE } from "../../utils/constants";
import { DealInApp } from "./DealInApp";

export function LandingPage() {
  useEffect(() => {
    document.title = PAGE_TITLE;

    (window as any).gtag("event", "Home");
  });

  return (
    <>
      <Header />
      <Hero />
      <Deal />
      <WhyNegociate />
      <OftenDoubts />
      <DealInApp />
    </>
  );
}
