import api from "../api";
import { FaqResponse } from "./types";

const PATH = "faq/api";

export const Faq = {
  perguntas: () =>
    api.get<FaqResponse>(`${PATH}/CodigoAssunto`, {
      params: {
        CodigoAssunto: 18,
      },
    }),
};
