export function Invoice() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3333 28H8.66667C7.19333 28 6 26.8067 6 25.3333V6.66667C6 5.19333 7.19333 4 8.66667 4H23.3333C24.8067 4 26 5.19333 26 6.66667V25.3333C26 26.8067 24.8067 28 23.3333 28Z"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.666 10H21.3327"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.666 14H21.3327"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.666 20H21.3327"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.666 24H21.3327"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6667 9.00004V8.00004"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6667 15V16"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.93 14.2853C11.1887 14.7093 11.6313 15.0027 12.166 15.0027H12.666H13.262C14.038 15.0027 14.666 14.3733 14.666 13.5987C14.666 12.9547 14.2273 12.3933 13.6033 12.236L11.7287 11.7667C11.1047 11.6093 10.666 11.048 10.666 10.404C10.666 9.62933 11.2953 9 12.07 9H12.666H13.166C13.6993 9 14.1407 9.292 14.3993 9.71467"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
