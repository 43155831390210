import { keyframes } from "@stitches/react";
import { styled } from "../../styles";

const spinner = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});

export const Loader = styled("div", {
  size: 32,
  margin: "auto",

  svg: {
    size: 32,
    animation: `${spinner} 1.5s infinite linear`,
  },
});
