import { Link } from "react-router-dom";
import image from "@/assets/images/Havan-Negociacao-Aplicativo.webp";
import barsMobile from "@/assets/images/barsMobile.svg";
import cardHand from "@/assets/images/cardHand.svg";
import havanAccount from "@/assets/images/havanAccount.svg";
import { QrCodeApp } from "@/assets/icons/solid/QrCodeApp";
import { Button } from "@/components/Button";
import styles from "./deal_in_app.module.css";

export function DealInApp() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.content}>
        <img
          src={image}
          alt="Tela do Cartão Havan no aplicativo onde é possível acessar fatura e acompanhar as parcelas do acordo de negociação Havan"
        />
        <div className={styles.info}>
          <h2>Negocie sua dívida pelo aplicativo Havan</h2>
          <div className={styles.info_cards}>
            <div className={styles.info_card}>
              <img src={barsMobile} alt="" />
              <h3>Mais praticidade</h3>
              <p>Acompanhe as parcelas da negociação pelo aplicativo</p>
            </div>
            <div className={styles.info_card}>
              <img src={cardHand} alt="" />
              <h3>Limpe seu nome</h3>
              <p>Escolha o parcelamento e a forma de pagamento</p>
            </div>
            <div className={styles.info_card}>
              <img src={havanAccount} alt="" />
              <h3>Fácil e seguro</h3>
              <p>Mais comodidade e segurança</p>
            </div>
          </div>
          <div className={styles.qr_code_wrapper}>
            <QrCodeApp />
            <p>
              <span>Aponte a câmera do celular para o QR Code e </span>
              <Link to="//havan.page.link/nYJz" target="_blank">
                Baixe o aplicativo
              </Link>
            </p>
          </div>
          <Button as={Link} to="//havan.page.link/nYJz">
            Baixar aplicativo
          </Button>
        </div>
      </div>
    </section>
  );
}
