import * as Select from "@radix-ui/react-select";
import { keyframes } from "@stitches/react";
import { styled } from "../../../../styles";
import { Button } from "../../../../components/Button";

export const Value = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
});

export const Header = styled("header", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const InputDateContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  textAlign: "start",

  label: {
    padding: "3px 4px",
    marginBottom: 4,
  },

  select: {
    option: {
      padding: "$3 $4",
      height: "48px",
    },
  },
});

export const Heading = styled("h2", {
  marginBottom: 0,
});

export const Loader = styled("div", {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translateX(-50%) translateY(-50%)",
  margin: 0,
});

export const InstallmentContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 24,
  position: "relative",
  variants: {
    isLoading: {
      true: {
        "& p:not(:last-child), & div:not(:last-child)": {
          opacity: 0.25,
        },
        [`${Loader}`]: {
          display: "block",
        },
      },
      false: {
        [`${Loader}`]: {
          display: "none",
        },
      },
    },
  },
  defaultVariants: {
    isLoading: false,
  },
});

export const Text = styled("p", {
  lineHeight: "22px",
  color: "$neutralDarkest",
});

export const ParcelaContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 8,

  "@min_width_481": {
    gap: 24,
  },
});

export const Parcela = styled("p", {
  fontWeight: "$bold",
  fontSize: "$lg",
  lineHeight: "33px",
  color: "$neutralDarkest",

  "@min_width_481": {
    minWidth: 200,
  },
});

export const ButtonIcon = styled("button", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 56,
  height: 40,
  backgroundColor: "$primaryLight",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "$primaryMedium",
  borderRadius: 8,
  color: "$primaryMedium",

  "&:hover": {
    cursor: "pointer",
  },

  "&:disabled": {
    backgroundColor: "$primaryLighter",
    borderColor: "$neutralMedium",
    color: "$neutralMedium",
  },

  "@max_width_768": {
    width: 50,
    flexShrink: 0,
  },
});

export const Shortcut = styled("div", {
  display: "flex",
  justifyContent: "center",
  gap: 40,
  width: 194,
  margin: "auto",

  span: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2px 8px",
    width: 33,
    height: 23,
    backgroundColor: "$primaryLighter",
    borderRadius: 24,
    fontWeight: "$bold",
    fontSize: "$xxs",
    lineHeight: "19px",
    color: "$primaryMedium",

    "&:hover": {
      cursor: "pointer",
    },
  },
});

const chevronOpenKeyframes = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(180deg)" },
});

export const SelectTrigger = styled(Select.Trigger, {
  height: 56,
  background: "$neutralLighter",
  border: "1.5px solid $primaryMedium",
  borderRadius: 8,
  outline: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "15.5px 16px",
  boxSizing: "border-box",

  fontFamily: "$default",
  fontWeight: "$bold",
  fontSize: "$sm",
  lineHeight: "25px",
  color: "$neutralDarkest",

  position: "relative",

  "&:hover": {
    cursor: "pointer",
  },

  svg: {
    color: "$primaryMedium",
  },

  '&[data-state="open"] svg': {
    transform: "rotate(180deg)",
    animation: `${chevronOpenKeyframes} 0.2s`,
  },
});

export const SelectContent = styled(Select.Content, {
  backgroundColor: "$neutralLighter",
  border: "1.5px solid $primaryLight",
  width: "100%",
  borderRadius: 8,

  "&.full": {
    width: "var(--radix-select-trigger-width)",
    maxHeight: "var(--radix-select-content-available-height)",
  },
});

export const SelectIcon = styled(Select.Icon, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const SelectPortal = styled(Select.Portal, {
  width: "100%",
  boxSizing: "border-box",
});

export const SelectItem = styled(Select.Item, {
  padding: "$3 $4",
  fontWeight: "$bold",
  fontSize: "$sm",
  lineHeight: "25px",
  color: "$primaryMedium",

  "&:hover": {
    cursor: "pointer",
  },

  "&:not(:last-child)": {
    borderBottom: "1px solid $primaryLight",
  },
});

export const NavigationButtons = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 16,

  [`${Button}`]: {
    width: "100%",
  },
});
