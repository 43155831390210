import {
  createBrowserRouter,
  createRoutesFromChildren,
  Route,
} from "react-router-dom";
import { Layout } from "./layouts";
import { RenegociarLayout } from "./layouts/RenegociarLayout";
import { LandingPage } from "./pages/LandingPage";
import { Pagamento } from "./pages/renegociar/pagamento";
import { RenegociarHome } from "./pages/renegociar/home";
import { LayoutPagamento } from "./layouts/Pagamento";
import { Renegociar } from "./pages/renegociar";
import { SemDividas } from "./pages/semDividas";
import { Protected } from "./auth/layout";
import { RenegociarAcordoLayout } from "./layouts/RenegociarAcordoLayout";
import { SemDividasLayout } from "./layouts/SemDividas";
import { PoliticaDePrivacidade } from "./pages/PoliticaDePrivacidade";

export default createBrowserRouter(
  createRoutesFromChildren(
    <Route path="/*" element={<Layout />}>
      <Route index element={<LandingPage />} />

      <Route path="/*" element={<Protected />}>
        <Route path="sem-dividas" element={<SemDividasLayout />}>
          <Route index element={<SemDividas />} />
        </Route>
        <Route path="pagamento" element={<LayoutPagamento />}>
          <Route index element={<Pagamento />} />
        </Route>
      </Route>

      <Route path="negociar" element={<RenegociarAcordoLayout />}>
        <Route index element={<RenegociarHome />} />
        <Route path="acordo" element={<Protected />}>
          <Route path="" element={<RenegociarLayout />}>
            <Route index element={<Renegociar />} />
          </Route>
        </Route>
      </Route>

      <Route
        path="politica-de-privacidade"
        element={<PoliticaDePrivacidade />}
      />
    </Route>
  )
);
