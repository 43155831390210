import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { minutesToMilliseconds } from "date-fns";
import { AxiosError } from "axios";
import { AuthContext } from "./context";
import { AuthState, Credentials } from "./types";
import { Auth } from "../services/auth";
import { LocalStorage } from "../enums/LocalStorage";
import { useAlert } from "../components/Alert/hook";
import { ResponseError } from "../services/types";
import { clearLocalStorage, isTokenExpired } from "./helpers";
import { SituacaoCadastro } from "@/enums/SituacaoCadastro";

const ONE_MINUTE_IN_MILLISECONDS = minutesToMilliseconds(1);

export function AuthProvider({ children }: PropsWithChildren) {
  const alert = useAlert();
  const navigate = useNavigate();
  const [auth, setAuth] = useState<AuthState>(() => {
    const token = localStorage.getItem(LocalStorage.ACCESS_TOKEN);

    if (token) {
      const { exp } = jwtDecode<JwtPayload & { doc: string }>(token);

      if (!isTokenExpired(exp!)) {
        const userData = JSON.parse(
          localStorage.getItem(LocalStorage.USER_DATA)!
        ) as AuthState;

        return { ...userData };
      }
    }

    clearLocalStorage();
    return {} as AuthState;
  });

  function logout() {
    clearLocalStorage();
    setAuth({} as AuthState);
    navigate("/");
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const token = localStorage.getItem(LocalStorage.ACCESS_TOKEN);

      if (token) {
        const { exp } = jwtDecode<JwtPayload>(token);

        if (exp && isTokenExpired(exp)) {
          logout();
        }
      }
    }, ONE_MINUTE_IN_MILLISECONDS);

    return () => clearInterval(interval);
  }, []);

  function saveState(state: AuthState) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, state.token);
    localStorage.setItem(LocalStorage.USER_DATA, JSON.stringify(state));

    setAuth(state);
    navigate("/negociar");
  }

  useEffect(() => {
    window.addEventListener("message", (event) => {
      const { source } = event.data;

      if (source === "portal_cliente") {
        const { token, nome } = event.data.payload;

        if (!!token && !!nome) {
          const authState = {
            token,
            user: {
              nome: nome,
              statusCadastro: SituacaoCadastro.Completo,
            },
          } as AuthState;

          saveState(authState);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (window.opener) {
      if (!auth.token && !auth.user) {
        window.opener.postMessage(
          {
            source: "portal_negociacao",
            payload: {
              type: "channel_open",
            },
          },
          import.meta.env.VITE_PORTAL_CLIENTE_URL
        );

        return;
      }

      navigate("/negociar");
    }
  }, []);

  async function attempt({ cpf, birthdate }: Credentials) {
    return Auth.attempt({ documento: cpf, dataNascimento: birthdate })
      .then((response) => {
        const { nome, statusCadastro, token } = response.data.result;

        const authState = {
          token,
          user: { cpf, birthdate, nome, statusCadastro },
        } as AuthState;

        saveState(authState);

        return true;
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response?.data.errors) {
          error.response.data.errors.forEach((message) => {
            alert.alert("Atenção", message);
          });
        }
        return false;
      });
  }

  const providerValues = useMemo(() => ({ attempt, auth, logout }), [[]]);

  return (
    <AuthContext.Provider value={providerValues}>
      {children}
    </AuthContext.Provider>
  );
}
