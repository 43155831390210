export function CircleInformation() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM11.3617 16.846C11.5111 17.014 11.7257 17.098 12.0057 17.098C12.2951 17.098 12.5144 17.014 12.6637 16.846C12.8131 16.678 12.8877 16.4447 12.8877 16.146V11.008C12.8877 10.7 12.8084 10.4667 12.6497 10.308C12.5004 10.14 12.2857 10.056 12.0057 10.056C11.7257 10.056 11.5111 10.14 11.3617 10.308C11.2124 10.4667 11.1377 10.7 11.1377 11.008V16.146C11.1377 16.4447 11.2124 16.678 11.3617 16.846ZM11.2357 8.516C11.4224 8.67466 11.6791 8.754 12.0057 8.754C12.3417 8.754 12.5984 8.67466 12.7757 8.516C12.9531 8.348 13.0417 8.11933 13.0417 7.83C13.0417 7.53133 12.9531 7.30266 12.7757 7.144C12.5984 6.98533 12.3417 6.906 12.0057 6.906C11.6791 6.906 11.4224 6.98533 11.2357 7.144C11.0584 7.30266 10.9697 7.53133 10.9697 7.83C10.9697 8.11933 11.0584 8.348 11.2357 8.516Z"
        fill="currentcolor"
      />
    </svg>
  );
}
