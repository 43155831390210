export function Cartao() {
  return (
    <svg
      width="23"
      height="17"
      viewBox="0 0 23 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5878 15.6731L2.0272 15.618C1.33067 15.6159 0.767648 15.0495 0.7697 14.353L0.802848 3.18283C0.804901 2.4863 1.37129 1.92327 2.06782 1.92532L20.6284 1.98044C21.3249 1.9825 21.888 2.54888 21.8859 3.24542L21.8528 14.4156C21.8506 15.1121 21.2843 15.6752 20.5878 15.6731Z"
        fill="#006601"
      />
      <path
        d="M20.932 15.0747L2.37144 15.0196C1.67491 15.0175 1.11189 14.4511 1.11394 13.7546L1.14709 2.5844C1.14914 1.88787 1.71552 1.32485 2.41206 1.3269L20.9726 1.38202C21.6692 1.38407 22.2322 1.95046 22.2301 2.64699L22.197 13.8172C22.1949 14.5137 21.6286 15.0767 20.932 15.0747Z"
        fill="#64B876"
      />
      <path
        d="M7.10529 11.5551C7.10449 11.1412 7.3364 10.8053 7.62328 10.8048L8.92188 10.8023C9.20875 10.8017 9.44196 11.1367 9.44277 11.5505C9.44357 11.9643 9.21166 12.3002 8.92478 12.3008L7.62618 12.3033C7.3393 12.3039 7.10609 11.9689 7.10529 11.5551Z"
        fill="#C3FFC4"
      />
      <path
        d="M14.3774 11.541C14.3766 11.1272 14.6085 10.7913 14.8954 10.7907L16.194 10.7882C16.4809 10.7876 16.7141 11.1226 16.7149 11.5364C16.7157 11.9502 16.4838 12.2862 16.1969 12.2867L14.8983 12.2892C14.6114 12.2898 14.3782 11.9548 14.3774 11.541Z"
        fill="#C3FFC4"
      />
      <path
        d="M3.46922 11.5621C3.46842 11.1483 3.70033 10.8124 3.98721 10.8118L5.28581 10.8093C5.57269 10.8088 5.8059 11.1438 5.8067 11.5576C5.8075 11.9714 5.57559 12.3073 5.28871 12.3078L3.99011 12.3103C3.70324 12.3109 3.47003 11.9759 3.46922 11.5621Z"
        fill="#C3FFC4"
      />
      <path
        d="M10.7414 11.548C10.7406 11.1342 10.9725 10.7983 11.2593 10.7977L12.5579 10.7952C12.8448 10.7947 13.078 11.1297 13.0788 11.5435C13.0796 11.9573 12.8477 12.2932 12.5608 12.2938L11.2623 12.2963C10.9754 12.2968 10.7422 11.9618 10.7414 11.548Z"
        fill="#C3FFC4"
      />
      <rect
        x="2.74316"
        y="4.66584"
        width="2.85247"
        height="1.41639"
        rx="0.708193"
        transform="rotate(-0.399564 2.74316 4.66584)"
        fill="#C4FFC4"
      />
      <g opacity="0.45">
        <path
          opacity="0.28"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6591 1.38074L14.7561 1.36319L8.20517 15.0365L14.1082 15.054L20.6591 1.38074Z"
          fill="white"
        />
        <path
          opacity="0.28"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1468 1.3615L13.604 1.3599L7.05307 15.0332L7.59596 15.0348L14.1468 1.3615Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
