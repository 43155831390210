import { createContext } from "react";
import { AuthState, Credentials } from "./types";

export interface AuthContextData {
  attempt(credentials: Credentials): Promise<boolean>;
  logout: () => void;
  auth: AuthState;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);
