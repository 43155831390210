export function Plus() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9994 4.76642C12.4136 4.76642 12.7494 5.1022 12.7494 5.51642V11.9767H19.2097C19.6239 11.9767 19.9597 12.3125 19.9597 12.7267C19.9597 13.141 19.6239 13.4767 19.2097 13.4767H12.7494V19.9371C12.7494 20.3513 12.4136 20.6871 11.9994 20.6871C11.5852 20.6871 11.2494 20.3513 11.2494 19.9371V13.4767H4.78906C4.37485 13.4767 4.03906 13.141 4.03906 12.7267C4.03906 12.3125 4.37485 11.9767 4.78906 11.9767H11.2494V5.51642C11.2494 5.1022 11.5852 4.76642 11.9994 4.76642Z"
        fill="currentcolor"
      />
    </svg>
  );
}
