export function Mobile() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6665 6.66663C5.6665 3.9052 7.90508 1.66663 10.6665 1.66663H21.3332C24.0946 1.66663 26.3332 3.9052 26.3332 6.66663V25.3333C26.3332 28.0947 24.0946 30.3333 21.3332 30.3333H10.6665C7.90508 30.3333 5.6665 28.0947 5.6665 25.3333V6.66663ZM10.6665 3.66663C9.00965 3.66663 7.6665 5.00977 7.6665 6.66663V25.3333C7.6665 26.9901 9.00965 28.3333 10.6665 28.3333H21.3332C22.99 28.3333 24.3332 26.9901 24.3332 25.3333V6.66663C24.3332 5.00977 22.99 3.66663 21.3332 3.66663H10.6665Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6665 25.3333C13.6665 24.781 14.1142 24.3333 14.6665 24.3333H17.3332C17.8855 24.3333 18.3332 24.781 18.3332 25.3333C18.3332 25.8856 17.8855 26.3333 17.3332 26.3333H14.6665C14.1142 26.3333 13.6665 25.8856 13.6665 25.3333Z"
        fill="currentcolor"
      />
    </svg>
  );
}
