import { styled } from "../../styles";

export const Container = styled("div", {
  backgroundColor: "$primaryDark",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "24px 0",
  gap: 8,
});

export const Heading = styled("h1", {
  fontWeight: "$extraBold",
  lineHeight: "44px",
  color: "$neutralLighter",

  "@max_width_480": {
    fontSize: "$lg",
    lineHeight: "33px",
    textAlign: "center",
    padding: "0 60px",
  },
});

export const Text = styled("p", {
  fontSize: "$xxs",
  lineHeight: "19px",
  color: "$primaryLight",
  display: "flex",
  gap: 8,
  alignItems: "center",
});
