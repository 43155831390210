export function Calendar() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.24577 1.04169C7.59095 1.04169 7.87077 1.32151 7.87077 1.66669V2.29169H13.2874V1.66669C13.2874 1.32151 13.5673 1.04169 13.9124 1.04169C14.2576 1.04169 14.5374 1.32151 14.5374 1.66669V2.29169H14.7458C16.9319 2.29169 18.7041 4.06389 18.7041 6.25002V15C18.7041 17.1861 16.9319 18.9584 14.7458 18.9584H6.41243C4.22631 18.9584 2.4541 17.1861 2.4541 15V6.25002C2.4541 4.06389 4.22631 2.29169 6.41243 2.29169H6.62077V1.66669C6.62077 1.32151 6.90059 1.04169 7.24577 1.04169ZM6.62077 3.54169H6.41243C4.91666 3.54169 3.7041 4.75425 3.7041 6.25002V6.87502H17.4541V6.25002C17.4541 4.75425 16.2415 3.54169 14.7458 3.54169H14.5374V4.16669C14.5374 4.51187 14.2576 4.79169 13.9124 4.79169C13.5673 4.79169 13.2874 4.51187 13.2874 4.16669V3.54169H7.87077V4.16669C7.87077 4.51187 7.59095 4.79169 7.24577 4.79169C6.90059 4.79169 6.62077 4.51187 6.62077 4.16669V3.54169ZM17.4541 8.12502H3.7041V15C3.7041 16.4958 4.91666 17.7084 6.41243 17.7084H14.7458C16.2415 17.7084 17.4541 16.4958 17.4541 15V8.12502Z"
        fill="#076BEE"
      />
    </svg>
  );
}
