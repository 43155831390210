import { Fragment, useState } from "react";
import {
  FooterPoliticasWrapper,
  Header,
  HeadingWrapper,
  LinkForm,
  PoliticaContainer,
  TextLeuAcordo,
} from "./styles";
import { Heading } from "../../components/Heading";
import { Button } from "../../components/Button";
import { Checkbox } from "../../components/Checkbox";
import { Header as LandingHeader } from "../LandingPage/Header";
import { Text } from "../../components/Text";
import { useAuth } from "../../auth/hook";
import { useNavigate } from "react-router-dom";

export function PoliticaDePrivacidade() {
  const [accptedTerms, setAccptedTerms] = useState(false);
  const { auth } = useAuth();
  const navigate = useNavigate();

  return (
    <Fragment>
      <LandingHeader />
      <Header>
        <Heading fontSize={{ "@initial": "lg", "@min_width_769": "xl" }}>
          Políticas de privacidade Havan
        </Heading>
      </Header>
      <PoliticaContainer>
        <HeadingWrapper>
          <Heading fontSize="lg">Nossa política de privacidade</Heading>
          <Heading fontSize="lg">
            A Havan protege os seus dados pessoais
          </Heading>
        </HeadingWrapper>
        <Text>
          Nós da HAVAN respeitamos a sua privacidade e estamos comprometidos a
          proteger seus dados pessoais. Esta Política de Privacidade descreve as
          regras que a Havan utiliza na hora de tratar dados pessoais com
          relação a coleta, registro, armazenamento, uso, compartilhamento,
          enriquecimento e eliminação de dados. Essas regras estão de acordo com
          a LGPD (Lei Federal nº 13.709/18).
        </Text>
        <br />
        <Text>
          Quer saber como funciona o tratamento de dados aqui com a gente? Nós
          explicamos a você!
        </Text>
        <br />
        <Heading as="h3">
          1. Conceitos que preciso saber para entender melhor essa política:
        </Heading>
        <br />
        <Text>
          <strong>Dado pessoal:</strong> É a informação relacionada a uma pessoa
          física, que seja capaz de identificar a pessoa ou tornar possível a
          sua identificação, como por exemplo: Nome, CPF, RG, endereço, telefone
          celular, e-mail, etc.
        </Text>
        <br />
        <Text>
          <strong>Dado sensível:</strong> São todas as informações vinculadas a
          uma pessoa física, relacionadas a origem racial ou étnica, convicções
          religiosas, opinião política, filiação a sindicato ou a organização de
          caráter religioso, dados referentes a saúde, vida sexual, dado
          genético ou biométrico.
        </Text>
        <br />
        <Text>
          <strong>Titular de dados:</strong> É a pessoa física a quem se refere
          o dado pessoal tratado pela Havan, como por exemplo: clientes,
          colaboradores, visitantes dos estabelecimentos e usuários.
        </Text>
        <br />
        <Text>
          <strong>Tratamento:</strong> É a operação realizada com seus dados
          pessoais, como por exemplo: a coleta, utilização, acesso,
          armazenamento, eliminação, comunicação, entre outros.
        </Text>
        <br />
        <Text>
          <strong>Cookies:</strong> São arquivos de texto que um site envia ao
          navegador de um usuário, com dados sobre o seu perfil e comportamento
          de navegação. São responsáveis por alguns benefícios como, por
          exemplo, fazer com que a plataforma se encaixe em sua tela, oferecer
          produtos e negócios conforme suas preferências e melhorar suas
          experiências.
        </Text>
        <br />
        <Text>
          <strong>Consentimento:</strong> É a autorização de Tratamento de Dados
          Pessoais com base na manifestação livre, informada e inequívoca do
          Titular ao concordar com o Tratamento de seus Dados Pessoais para uma
          finalidade específica, informada por nós.
        </Text>
        <br />
        <Text>
          <strong>Plataformas:</strong> São os sites, e-commerce e aplicativos
          administrados pela Havan.
        </Text>
        <br />
        <Heading as="h3">2. Por quê a havan trata meus dados pessoais?</Heading>
        <br />
        <Text>
          Temos um comprometimento com você em oferecer sempre os melhores
          produtos e serviços, acreditando no seu poder de escolha. Garantimos
          que quem tem o controle de seus dados é você.
        </Text>
        <br />
        <Text>
          Na compra de algum produto ou contratação de serviços é comum
          coletarmos dados pessoais para efetivar a sua compra, assim como
          alguns de nossos produtos, negócios e experiências que envolvem a
          realização de cadastros em nossa plataforma.{" "}
        </Text>
        <br />
        <Text>
          O objetivo da coleta de dados é garantir um atendimento de qualidade e
          uma melhor experiência com a Havan, seja para receber uma compra em
          sua casa, oferecer anúncios mais atraentes e personalizados, acessar a
          rede wi-fi e garantir sua segurança em nossos estabelecimentos. Esses
          são alguns exemplos que mostram como nossas atividades precisam
          utilizar dados pessoais, sempre de acordo com a LGPD e dentro de suas
          expectativas.
        </Text>
        <br />
        <Text>
          Se após a leitura dessa Política você ainda tiver dúvidas, fique à
          vontade para entrar em contato com a gente através dos meios
          disponíveis no item 11 dessa política, ficaremos felizes em atendê-lo.
        </Text>
        <br />
        <Heading as="h3">
          3. Quais tipos de dados podem ser coletados pela havan?
        </Heading>
        <br />
        <Text>Nós podemos coletar os seguintes dados pessoais:</Text>
        <br />
        <Text>
          <strong>Durante o cadastro:</strong> Nome completo, CPF, RG, data de
          nascimento, telefone fixo e celular, gênero, endereço completo,
          e-mail, filiação, estado civil, escolaridade,{" "}
        </Text>
        <br />
        <Text>
          <strong>Interação em nosso site, aplicativo ou redes sociais:</strong>{" "}
          Identidade, CNH, RNE, RNM, Carteira Funcional; Telefones de Contato;
          Endereço; E-mail; Informações de cartão de crédito ou cartão HAVAN;
          Biometria Facial; Dados de trabalho (Endereço, Telefone, enquadramento
          funcional, comprovante de renda); Código do cliente; Gênero; Login e
          senha; Geolocalização; Histórico de Compra; Perfil de Consumo.
        </Text>
        <br />
        <Text>
          <strong>Seleção de recrutamento de candidatos a vagas:</strong> Nome,
          identidade, CNH, Endereço, telefones, e-mail, currículo.
        </Text>
        <br />
        <Text>
          <strong>Interação em espaços físicos da Havan:</strong> Identidade,
          CNH, RNE, RNM, Carteira Funcional; Telefones de Contato; Endereço;
          E-mail; Informações de cartão de crédito; Nome; Biometria Facial;
          Dados de trabalho (Endereço, Telefone, enquadramento funcional,
          comprovante de renda); Código do cliente; Inscrição do INSS; Imagens
          de câmeras de segurança.
        </Text>
        <br />
        <Text>
          <strong>Rede Wi-fi:</strong> Endereço físico do dispositivo (endereço
          MAC);
        </Text>
        <br />
        <Text>
          <strong>Outras informações que poderão ser coletadas:</strong>{" "}
          Informações fornecidas por você, voluntariamente, como por meio de
          comunicações com a Havan em redes sociais ou em comentários e
          avaliações de produtos.
        </Text>
        <br />
        <Text>
          Cópias de documentos enviados por você como comprovante de residência,
          dados bancários ou cópia de um documento de identidade, geralmente
          utilizados para comprovação de seu cadastro ou validação de sua
          identidade
        </Text>
        <br />
        <Text>
          Coletamos esses dados apenas para alcançar as finalidades
          estabelecidas no tópico seguinte.
        </Text>
        <br />
        <Heading>4. Como nós coletamos seus dados pessoais?</Heading>
        <br />
        <Text>
          Os dados que coletamos dependem da forma que você se relaciona com a
          gente. Por exemplo: quando você utiliza o aplicativo Havan os dados
          coletados serão diferentes de quando você for em uma das nossas lojas
          fazer um cadastro para solicitar o cartão Havan, por exemplo. E nós
          utilizamos esses dados para garantir um atendimento de qualidade e uma
          melhor experiência na sua compra. As finalidades da coleta desses
          dados podem ser as seguintes:
        </Text>
        <br />
        <Text>
          Coletamos os dados que você nos fornece por meio de cadastro em um dos
          nossos estabelecimentos ou plataformas, com a finalidade de:
        </Text>
        <br />
        <ul>
          <li>
            <Text>Confirmar a identidade do usuário;</Text>
          </li>
          <li>
            <Text>
              Possibilitar ao usuário a interação com as nossas plataformas;
            </Text>
          </li>
          <li>
            <Text>
              Gerenciar compras e pedidos (como, por exemplo, processar e
              acompanhar seu pedido, incluindo a entrega do produto no endereço
              que você indicou ou emissão de Nota Fiscal);
            </Text>
          </li>
          <li>
            <Text>
              Possibilitar a retirada das mercadorias adquiridas na modalidade “
              Retira Fácil”
            </Text>
          </li>
          <li>
            <Text>
              Permitir ao cliente a criação de uma “ Lista de Presentes ”;
            </Text>
          </li>
          <li>
            <Text>Negociar dívida Havan.</Text>
          </li>
        </ul>
        <br />
        <Text>
          Coletamos dados que você nos fornece quando visita um dos nossos
          estabelecimentos físicos, com a finalidade de:
        </Text>
        <br />
        <ul>
          <li>
            <Text>Processar compras;</Text>
          </li>
          <li>
            <Text>Disponibilizar acesso à internet;</Text>
          </li>
          <li>
            <Text>
              Identificar você por meio de reconhecimento facial e assim
              direcionar um melhor atendimento;
            </Text>
          </li>
          <li>
            <Text>Realizar avaliação de crédito;</Text>
          </li>
          <li>
            <Text>
              Emitir, gerenciar, realizar cobrança de faturas e emitir boletos
              do Cartão HAVAN;
            </Text>
          </li>
          <li>
            <Text>
              Realizar a gestão financeira (recebimentos e cobranças);
            </Text>
          </li>
          <li>
            <Text>
              Emitir Notas Fiscais e cumprir outras obrigações de ordem legal;
            </Text>
          </li>
          <li>
            <Text>Manter cadastros e bancos de dados;</Text>
          </li>
          <li>
            <Text>
              Prestar assistências no pós-venda, como realização de trocas e
              garantias.
            </Text>
          </li>
          <li>
            <Text>
              Manter a segurança dos estabelecimentos e pessoas, através do
              monitoramento interno;
            </Text>
          </li>
          <li>
            <Text>
              Traçar um perfil de consumo, a partir da localização dos usuários
              nos setores de nossa loja;
            </Text>
          </li>
          <li>
            <Text>Viabilizar os meios de pagamento.</Text>
          </li>
        </ul>
        <br />
        <Text>
          Coletamos os dados que você nos fornece por meio de cadastro em
          plataformas e/ou estabelecimentos, além de outras interações que você
          tenha com a gente, com a finalidade de:
        </Text>
        <br />
        <ul>
          <li>
            <Text>
              Realizar a comunicação publicitária, para fins de divulgação de
              promoções, sorteios, pesquisas, mala direta, SMS, newsletters,
              marketing em geral ou outras formas de comunicação;
            </Text>
          </li>
          <li>
            <Text>
              Direcionar conteúdos e publicidade, conforme o seu perfil e
              preferências, diretamente ou indiretamente;
            </Text>
          </li>
          <li>
            <Text>
              Receber e realizar contatos com o Usuário para prestar
              atendimento;
            </Text>
          </li>
          <li>
            <Text>
              Interações comerciais com representantes de fornecedores, para
              negociação e aquisição de matéria prima e serviços;
            </Text>
          </li>
          <li>
            <Text>
              Recebimento, análise, contato e demais procedimentos preliminares
              à seleção de candidatos para integrar o quadro de funcionários da
              HAVAN. Neste caso os dados podem inclusive ser compartilhados com
              terceiros que prestem apoio no processo.
            </Text>
          </li>
        </ul>
        <br />
        <Text>
          Coletamos dados quando você entra em contato com a gente por meio de
          nossos serviços de atendimento, seja por telefone, e-mail, chat ou
          presencialmente, com a finalidade de:
        </Text>
        <br />
        <ul>
          <li>
            <Text>Atender solicitações;</Text>
          </li>
          <li>
            <Text>Responder dúvidas;</Text>
          </li>
          <li>
            <Text>Realizar pesquisas de satisfação;</Text>
          </li>
          <li>
            <Text>Direcionar reclamações e solicitações aos responsáveis;</Text>
          </li>
        </ul>
        <br />
        <Text>
          Coletamos dados por meio de cookies, a coleta é feita automaticamente
          e esses dados são relativos à sua navegação e ao aparelho que usar
          para acessar as plataformas, com a finalidade de:
        </Text>
        <br />
        <ul>
          <li>
            <Text>
              Para realizar investigações e medidas de prevenção de fraudes ou
              de qualquer atitude ilícita;
            </Text>
          </li>
          <li>
            <Text>
              Exercer regularmente direitos, tais como cobrança, defesa judicial
              de interesses, negativação de dívidas, etc.;
            </Text>
          </li>
          <li>
            <Text>
              Prevenir cadastros fraudulentos e garantir sua segurança em nossas
              plataformas;
            </Text>
          </li>
          <li>
            <Text>
              Evitar ocorrências de fraudes, práticas ilícitas ou lavagem de
              dinheiro.
            </Text>
          </li>
        </ul>
        <br />
        <Heading as="h3">5. Com quem compartilhamos seus dados?</Heading>
        <br />
        <Text>
          A Havan atua com um conjunto de empresas parceiras para entregar a
          você uma melhor experiência no oferecimento de produtos e serviços, e
          em alguns casos nós precisamos compartilhar alguns dados pessoais com
          essas empresas. Esse compartilhamento é feito sempre buscando ao
          máximo a proteção da sua privacidade, por isso, avaliamos
          cuidadosamente nossos parceiros e priorizamos as obrigações
          contratuais de proteção de dados e segurança da informação. Aqui
          descrevemos algumas situações em que compartilhamos dados pessoais:
        </Text>
        <br />
        <ul>
          <li>
            <Text>
              <strong>Fornecedores:</strong> Nossos parceiros nos auxiliam a
              entregar a melhor experiência com a Havan. Entre esses
              fornecedores, temos como exemplo empresas de aplicação de
              questionários de satisfação, ferramentas e soluções tecnológicas,
              seleção e recrutamento de candidatos a vagas de emprego e empresas
              especializadas em marketing digital;
            </Text>
          </li>
          <li>
            <Text>
              <strong>Bureau de crédito:</strong> são empresas que nos auxiliam
              nas análises de crédito;
            </Text>
          </li>
          <li>
            <Text>
              <strong>Transportadoras:</strong> para realização de entregas ou
              trocas de mercadorias;
            </Text>
          </li>
          <li>
            <Text>
              <strong>Autoridades judiciais:</strong> Nós cumprimos a lei.
              Assim, se alguma autoridade com competência legal solicitar que a
              Havan compartilhe certos dados, iremos atender as necessidades
              regulatórias.
            </Text>
          </li>
        </ul>
        <br />
        <Heading as="h3">
          6. A Havan transfere dados para outros países?
        </Heading>
        <br />
        <Text>
          Sim. Alguns de nossos fornecedores e parceiros podem estar localizados
          fora do Brasil. Por esse motivo, alguns Dados Pessoais podem ser
          transferidos para outros países, para que possam retornar com o
          serviço contratado, aplicando-se sempre as leis brasileiras
          relacionadas à proteção de dados pessoais.
        </Text>
        <br />
        <Text>
          A HAVAN constantemente revisa os contratos de privacidade de dados com
          fornecedores e parceiros de outros países e garantimos que as
          transferências de dados sejam realizadas apenas com empresas que
          demonstrem estar em conformidade com a lei.
        </Text>
        <br />
        <Heading as="h3">7. Cookies e tecnologias de monitoramento</Heading>
        <br />
        <Text>
          Em geral podemos classificar os cookies nas seguintes categorias:
        </Text>
        <br />
        <Text>
          a) <strong>Cookies Necessários:</strong> asseguram o funcionamento
          correto do site. Esses cookies não podem ser desativados, pois sem
          eles, o funcionamento do site pode ser prejudicado.
        </Text>
        <br />
        <Text>
          b) <strong>Cookies de funcionalidade:</strong> servem para gravar
          dados fornecidos anteriormente, como por exemplo, suas informações de
          login, com a finalidade de melhorar e facilitar a experiência de
          navegação. Podem ser desativados pelo usuário.
        </Text>
        <br />
        <Text>
          c) <strong>Cookies de marketing:</strong> Servem para disponibilizar
          as melhores ofertas de produtos e serviços ao usuário. (Ex: Armazenam
          informações dos produtos comprados recentemente). Podem ser
          desativados pelo usuário.
        </Text>
        <br />
        <Text>
          d) <strong>Cookies de desempenho:</strong> Servem para armazenar
          informações coletadas no site, como o número de visitas, as páginas
          mais e menos acessadas e qualquer problema encontrado com mensagens de
          erro. Todas as informações coletadas por esses cookies são
          completamente anônimas, e servem apenas para melhorar o desempenho do
          site. Podem ser desativados pelo usuário.
        </Text>
        <br />
        <Text>
          e) <strong>Cookies de parceiros:</strong> esses cookies são inseridos
          por parceiros, como Facebook e Google, que coletam informações do
          Titular que visita nosso site. Esses cookies coletam informações que
          podem identificar o Titular e podem ser desativados pelo usuário.
        </Text>
        <br />
        <Text>
          Nós respeitamos o seu direito à privacidade, sendo assim, você pode
          optar por não permitir alguns tipos de cookies. Caso deseje remover os
          cookies instalados no seu computador, você pode eliminá-los
          manualmente por meio das configurações disponíveis no seu navegador,
          conforme, por exemplo, as instruções do <u>Google Chrome</u>,{" "}
          <u>Mozilla Firefox</u> , <u>Microsoft Edge</u> ou <u>Safari</u>. Vale
          lembrar que algumas das funcionalidades do site podem tornar-se
          indisponíveis após a exclusão de determinados cookies.
        </Text>
        <br />
        <Heading as="h3">
          8. Quais são meus direitos como titular de dados pessoais?
        </Heading>
        <br />
        <Text>
          Reforçamos que os dados pessoais são seus e você tem total direito
          sobre eles. Nós estamos comprometidos com o cumprimento desses
          direitos e vamos explicar como você pode exerce-los junto a Havan,
          através do canal dpo@havan.com.br ou do Formulário de Atendimento aos
          Titulares de Dados anexo em nosso site.
        </Text>
        <br />
        <Text>A LGPD lhe garante os seguintes direitos:</Text>
        <br />
        <ul>
          <li>
            <Text>
              <strong>
                Confirmação da existência de tratamento de dados pessoais:
              </strong>{" "}
              solicitar a confirmação sobre a existência de tratamento de seus
              dados pessoais junto a Havan;
            </Text>
          </li>
          <li>
            <Text>
              <strong>Acesso aos dados pessoais:</strong> você pode solicitar
              uma cópia dos dados pessoais tratados pela Havan;
            </Text>
          </li>
          <li>
            <Text>
              <strong>Correção de dados pessoais:</strong> o usuário pode
              corrigir dados que estejam incompletos, inexatos ou desatualizados
              através de nossas plataformas ou presencialmente em uma de nossas
              lojas;
            </Text>
          </li>
          <li>
            <Text>
              <strong>
                Anonimização, bloqueio ou eliminação: permite que você solicite:
              </strong>{" "}
              (a) anonimização dos seus dados, de forma a que eles não possam
              mais ser relacionados a você e, portanto, deixem de ser Dados
              Pessoais; (b) bloquear seus Dados, suspendendo temporariamente a
              sua possibilidade de Tratamento: e (c) eliminar seus Dados, caso
              em que apagaremos todos os seus Dados, salvo os casos previstos em
              lei.
            </Text>
          </li>
          <li>
            <Text>
              <strong>Portabilidade dos dados Pessoais:</strong> você tem o
              direito de solicitar, através de requisição por escrito, que seus
              dados sejam transferidos a outra empresa, desde que não viole a
              propriedade intelectual ou segredo de negócios da Havan. Da mesma
              forma, você pode pedir a outras empresas que enviem a Havan seus
              dados pessoais para facilitar a contratação dos nossos produtos,
              negócios e serviços, por exemplo.
            </Text>
          </li>
          <li>
            <Text>
              <strong>Informações sobre o compartilhamento:</strong>é o direito
              de saber com quais empresas, parceiros ou outras instituições a
              Havan compartilha dados pessoais;
            </Text>
          </li>
          <li>
            <Text>
              <strong>Revogação do consentimento:</strong> o usuário tem o
              direito de dar ou retirar seu consentimento. Nesse caso, alguns
              serviços não poderão ser prestados.
            </Text>
          </li>
          <li>
            <Text>
              <strong>Se opor a determinado tratamento de dados:</strong> A lei
              autoriza o Tratamento de Dados Pessoais mesmo sem o seu
              consentimento ou um contrato conosco. Nesse caso, é preciso
              demonstrar que há motivos legítimos para Tratar seus Dados, como,
              por exemplo, prevenir fraudes ou melhorar nossa comunicação com
              você. Caso você não concorde com esse Tratamento, poderá solicitar
              a interrupção.
            </Text>
          </li>
        </ul>
        <br />
        <Text>
          Todas as solicitações serão submetidas a uma prévia avaliação da sua
          identidade e da viabilidade do atendimento, a fim de cumprir com
          eventuais obrigações que impeçam o completo atendimento das
          solicitações dos titulares de direito.
        </Text>
        <br />
        <Heading as="h3">
          9. Por quanto tempo os dados pessoais serão armazenados?
        </Heading>
        <br />
        <Text>
          Os dados pessoais serão armazenados somente pelo tempo que for
          necessário para cumprir com as finalidades para as quais foram
          coletados, e quando necessários para cumprir uma obrigação legal e/ou
          regulatória (como em casos de processos judiciais).
        </Text>
        <br />
        <Text>
          Se o titular preferir, também pode estar solicitando eliminação dos
          seus dados, onde será analisado e executado pelo time de privacidade,
          exceto se houver qualquer hipótese legal que justifique o
          armazenamento dos dados. Nesse caso o titular será informado sobre o
          motivo do impedimento de eliminação.
        </Text>
        <br />
        <br />
        <Heading as="h3">
          10. Quais são as responsabilidades da havan e como ela protege meus
          dados pessoais?
        </Heading>
        <br />
        <Text>
          Nossa responsabilidade é proteger seus Dados Pessoais e utilizá-los
          somente para as finalidades descritas nesta Política. E para garantir
          a sua privacidade e a proteção dos seus Dados Pessoais, adotamos
          práticas de segurança e governança adequadas para nosso mercado,
          incluindo recursos tecnológicos avançados que garantem a segurança dos
          seus dados pessoais, Política de Segurança da Informação, a atuação do
          comitê de privacidade e segurança da informação, equipe altamente
          qualificada, ferramentas de alta densidade para processamento de
          tráfego de rede, ferramenta contra entrega de dados (DLP), antivírus
          com análise comportamental, DNSSec, firewall de aplicativos, fator
          múltiplo de autenticação, Análise Comportamental, CASB, técnicas de
          criptografia, avaliação de fornecedores que estão de acordo com a
          LGPD, dentre outras.
        </Text>
        <br />
        <Text>
          IMPORTANTE: A HAVAN informa que não solicita senhas, dados bancários
          ou cartões de crédito por e-mail ou SMS. Caso você receber alguma
          mensagem neste sentido, deverá desconsiderá-la e entrar em contato com
          a equipe de privacidade, cujos canais de contato se encontram no item
          a seguir.
        </Text>
        <br />
        <br />
        <Heading as="h3">
          11. Quem procuro para falar de dados pessoais?
        </Heading>
        <br />
        <Text>
          Caso ainda surgir alguma dúvida sobre esta Política de Privacidade ou
          precise interagir sobre assuntos envolvendo dados pessoais, poderá
          estar entrando em contato conosco. Nós temos uma equipe de privacidade
          e um encarregado (DPO), que estão à disposição nos seguintes contatos:
        </Text>
        <br />
        <Text>
          <strong>DPO (Encarregado):</strong>
        </Text>
        <Text>Daniel Yamakado</Text>
        <br />
        <Text>
          <strong>Endereço para correspondências:</strong>
        </Text>
        <Text>Rodovia Antônio Heil, 250 – Centro I, Brusque-SC, 88353-100</Text>
        <br />
        <Text>
          <strong>Canal DPO:</strong>
        </Text>
        <Text>dpo@havan.com.br</Text>
        <br />
        <Text>
          <strong>
            Formulário de solicitações de atendimento a titulares de dados
            pessoais:
          </strong>{" "}
          <LinkForm
            target="_blank"
            to="https://forms.office.com/Pages/ResponsePage.aspx?id=nICZVMDuHUmM6kbcfh_8-Dvb56yeEzVItlHcRqubq09UNURTRkszU0RWTTdKSUpYODE0T0hLSk1JNy4u"
          >
            Clique aqui
          </LinkForm>
        </Text>

        <br />
        <Heading as="h3">12. Mudança na política de privacidade</Heading>
        <br />
        <Text>
          Estamos sempre buscando melhorar a experiência dos nossos serviços e
          plataformas, e com isso esta Política de Privacidade pode passar por
          atualizações, por isso recomendamos visitas periódicas a esta página,
          para que você fique por dentro das modificações realizadas.
        </Text>
        <br />
        <FooterPoliticasWrapper>
          {!auth?.user ? (
            <Fragment>
              <Checkbox
                onCheckedChange={(value: boolean) => setAccptedTerms(value)}
                checked={accptedTerms}
              >
                <TextLeuAcordo>
                  Li e concordo com a <span>Política de privacidade Havan</span>
                </TextLeuAcordo>
              </Checkbox>
              <Button
                disabled={!accptedTerms}
                onClick={() => navigate("/", { state: { politicas: true } })}
              >
                Ver o melhor acordo
              </Button>
            </Fragment>
          ) : (
            <Button onClick={() => navigate("/negociar")}>Voltar</Button>
          )}
        </FooterPoliticasWrapper>
      </PoliticaContainer>
    </Fragment>
  );
}
