export function InvoiceDocument() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3366 28.005H8.66387C7.95627 28.005 7.2776 27.7242 6.77725 27.2238C6.27691 26.7235 5.99609 26.0448 5.99609 25.3372V6.66277C5.99609 5.95517 6.27691 5.2765 6.77725 4.77615C7.2776 4.27581 7.95627 3.995 8.66387 3.995H23.3366C24.0442 3.995 24.7229 4.27581 25.2233 4.77615C25.7236 5.2765 26.0044 5.95517 26.0044 6.66277V25.3372C26.0044 26.0448 25.7236 26.7235 25.2233 27.2238C24.7229 27.7242 24.0442 28.005 23.3366 28.005Z"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0562 7.82992C16.0121 7.82976 15.9697 7.84732 15.9386 7.87866C15.9075 7.91001 15.8903 7.95251 15.8908 7.99666C15.8908 8.06422 15.9316 8.1251 15.9941 8.15083C16.0565 8.17655 16.1284 8.16204 16.1759 8.11408C16.2235 8.06612 16.2375 7.99418 16.2112 7.93192C16.185 7.86966 16.1238 7.82938 16.0562 7.82992"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.666 13.3321H21.3371"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3355 21.3356H10.666"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.666 17.3339H21.3371"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
