import { InputHTMLAttributes } from "react";
import { CheckCircle } from "../../assets/icons/solid/CheckCircle";
import { CircleInformation } from "../../assets/icons/solid/CircleInformation";
import { CloseCircle } from "../../assets/icons/solid/CloseCircle";
import {
  Container,
  Content,
  Currency,
  HelperText,
  Label,
  StyledInput,
} from "./styles";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  isSuccess?: boolean;
  isError?: boolean;
  isMoney?: boolean;
  helperTextType?: "default" | "success" | "error";
  helperText?: string;
}

export function Input({
  label,
  isSuccess,
  isError,
  isMoney,
  helperText,
  helperTextType = "default",
  ...rest
}: Props) {
  return (
    <Container>
      <Label htmlFor={label}>{label}</Label>
      <Content isError={isError} isSuccess={isSuccess}>
        {isMoney && <Currency>R$</Currency>}
        {isSuccess && !isError && <CheckCircle />}
        {isError && !isSuccess && <CloseCircle />}
        <StyledInput {...rest} />
      </Content>
      {helperText && (
        <HelperText>
          <CircleInformation />
          {helperText}
        </HelperText>
      )}
    </Container>
  );
}

Input.displayName = "Input";
