import { Link } from "react-router-dom";
import { styled } from "../../../styles";

export const Container = styled("header", {
  position: "fixed",
  top: 0,
  zIndex: 3,
  backgroundColor: "$neutralLighter",
  width: "100vw",
  height: 100,
  boxSizing: "border-box",

  "@min_width_1025": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 $4",
  },
});

export const Content = styled("div", {
  display: "flex",
  alignItems: "center",
  width: "100%",

  "@max_width_1024": {
    justifyContent: "space-between",
    flexDirection: "column",

    "input + div": {
      display: "none",
    },

    "input:checked + div": {
      display: "flex",
    },
  },

  "@min_width_1025": {
    maxWidth: 1160,
    justifyContent: "center",
  },
});

export const LogoContainer = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  boxSizing: "border-box",

  "@max_width_1024": {
    width: "100%",
    padding: "26px $4",
  },
});

export const Logos = styled("div", {
  display: "flex",
  alignItems: "center",
});

export const ImageLogo = styled("img", {
  "@max_width_1024": {
    display: "none",
  },
});

export const ImageLogoLettering = styled("img", {
  "@min_width_1025": {
    display: "none",
  },
});

export const ToggleMenu = styled("button", {
  height: 48,
  width: 48,
  background: "$primaryLighter",
  color: "$primaryDark",
  borderRadius: 8,
  border: "none",

  "@min_width_1025": {
    display: "none",
  },
});

export const Links = styled("div", {
  "@max_width_1024": {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "$background",
    width: "100%",
    alignItems: "start",
  },

  "@min_width_1025": {
    display: "grid",
    gridTemplateColumns: "repeat(4, 190px)",
    gap: 4,
    gridAutoFlow: "row",
    marginLeft: "auto",
    svg: {
      display: "none",
    },
  },
});

export const NavButton = styled("button", {
  all: "unset",
  display: "flex",
  alignItems: "center",
  gap: 16,
  height: 56,
  boxSizing: "border-box",
  fontWeight: "$bold",

  "&:hover": {
    cursor: "pointer",
  },

  "@max_width_1024": {
    width: "100%",
    padding: "0 $4",
    color: "$neutralDarkest",
    fontSize: "$xxs",
    lineHeight: "19px",

    "& svg": {
      color: "$primaryMedium",
    },
  },
  "@min_width_1025": {
    padding: 8,
    margin: "auto",
    color: "$primaryDark",

    "&.active": {
      borderBottom: "1.5px solid $primaryDark",
    },
  },
});

export const LinkRenegociarDivida = styled(Link, {
  all: "unset",
  display: "flex",
  alignItems: "center",
  gap: 16,
  height: 56,
  padding: "0 $4",
  fontWeight: "$bold",
  boxSizing: "border-box",

  "&:hover": {
    cursor: "pointer",
  },

  "@max_width_1024": {
    width: "100%",
    color: "$neutralDarkest",
    fontSize: "$xxs",
    lineHeight: "19px",

    "& svg": {
      color: "$primaryMedium",
    },
  },
  "@min_width_1025": {
    backgroundColor: "$primaryDark",
    color: "$neutralLighter",
    display: "flex",
    justifyContent: "center",
    borderRadius: 8,

    "&:visited": {
      color: "$neutralLighter",
    },
  },
});
