export function Offer() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1963 8.20538L26.3496 8.45738C27.4056 8.68805 28.035 9.77738 27.707 10.808L27.3483 11.9334C27.131 12.6174 27.331 13.364 27.8616 13.848L28.735 14.6427C29.5336 15.3707 29.5336 16.6294 28.735 17.3574L27.8616 18.152C27.331 18.6347 27.131 19.3827 27.3483 20.0667L27.707 21.192C28.035 22.2227 27.4056 23.312 26.3496 23.5427L25.1963 23.7947C24.495 23.948 23.9483 24.4947 23.795 25.196L23.543 26.3494C23.3123 27.4054 22.223 28.0347 21.1923 27.7067L20.067 27.348C19.383 27.1307 18.6363 27.3307 18.1523 27.8614L17.3576 28.7347C16.6296 29.5334 15.371 29.5334 14.643 28.7347L13.8483 27.8614C13.3656 27.3307 12.6176 27.1307 11.9336 27.348L10.8083 27.7067C9.77764 28.0347 8.6883 27.4054 8.45764 26.3494L8.20564 25.196C8.0523 24.4947 7.50564 23.948 6.8043 23.7947L5.65097 23.5427C4.59497 23.312 3.96564 22.2227 4.29364 21.192L4.6523 20.0667C4.86964 19.3827 4.66964 18.636 4.13897 18.152L3.26697 17.3574C2.4683 16.6294 2.4683 15.3707 3.26697 14.6427L4.1403 13.848C4.67097 13.3654 4.87097 12.6174 4.65364 11.9334L4.29364 10.808C3.96564 9.77738 4.59497 8.68805 5.65097 8.45738L6.8043 8.20538C7.50564 8.05205 8.0523 7.50538 8.20564 6.80405L8.45764 5.65072C8.6883 4.59472 9.77764 3.96538 10.8083 4.29338L11.9336 4.65205C12.6176 4.86938 13.3643 4.66938 13.8483 4.13872L14.643 3.26538C15.371 2.46672 16.6296 2.46672 17.3576 3.26538L18.1523 4.13872C18.635 4.66938 19.383 4.86938 20.067 4.65205L21.1923 4.29338C22.223 3.96538 23.3123 4.59472 23.543 5.65072L23.795 6.80405C23.9483 7.50538 24.495 8.05205 25.1963 8.20538Z"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20L20 12"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3314 12C12.1474 12 11.998 12.1493 11.9994 12.3333C11.9994 12.5173 12.1487 12.6666 12.3327 12.6666C12.5167 12.6666 12.666 12.5173 12.666 12.3333C12.666 12.1493 12.5167 12 12.3314 12"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6653 19.3333C19.4813 19.3333 19.332 19.4827 19.3333 19.6667C19.3333 19.8507 19.4827 20 19.6667 20C19.8507 20 20 19.8507 20 19.6667C20 19.4827 19.8507 19.3333 19.6653 19.3333"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
