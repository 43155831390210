import { Outlet } from "react-router-dom";
import { SimulationProvider } from "../../providers/Simulation/provider";
import { Header } from "./styles";

export function RenegociarLayout() {
  return (
    <SimulationProvider>
      <Header>Negociar dívidas</Header>
      <Outlet />
    </SimulationProvider>
  );
}
