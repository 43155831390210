export function Copy() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.49295 2.54744C10.3514 1.98547 11.4658 1.78961 12.5795 1.78961L18.2558 1.78961C20.7599 1.78961 22.7899 3.81962 22.7899 6.32377V12C22.7899 13.1138 22.5941 14.2281 22.0321 15.0866C21.4382 15.9937 20.4867 16.5342 19.2018 16.5342C18.7876 16.5342 18.4518 16.1984 18.4518 15.7842C18.4518 15.3699 18.7876 15.0342 19.2018 15.0342C20.0068 15.0342 20.4743 14.7275 20.7771 14.265C21.1117 13.7539 21.2899 12.9762 21.2899 12V6.32377C21.2899 4.64805 19.9315 3.28961 18.2558 3.28961L12.5795 3.28961C11.6034 3.28961 10.8256 3.46785 10.3145 3.80245C9.85206 4.10518 9.54537 4.57272 9.54537 5.37773C9.54537 5.79194 9.20959 6.12773 8.79537 6.12773C8.38116 6.12773 8.04537 5.79194 8.04537 5.37773C8.04537 4.0928 8.5858 3.14129 9.49295 2.54744ZM2.36914 12C2.36914 9.49586 4.39915 7.46585 6.9033 7.46585H12.5795C15.0837 7.46585 17.1137 9.49586 17.1137 12V17.6762C17.1137 20.1804 15.0837 22.2104 12.5795 22.2104H6.9033C4.39915 22.2104 2.36914 20.1804 2.36914 17.6762V12ZM6.9033 8.96585C5.22758 8.96585 3.86914 10.3243 3.86914 12V17.6762C3.86914 19.352 5.22758 20.7104 6.9033 20.7104H12.5795C14.2552 20.7104 15.6137 19.352 15.6137 17.6762V12C15.6137 10.3243 14.2552 8.96585 12.5795 8.96585H6.9033Z"
        fill="white"
      />
    </svg>
  );
}
