export function Check(): JSX.Element {
  return (
    <svg
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6778 1.87828L4.87484 7.67782C4.44512 8.10739 3.74806 8.10739 3.31793 7.67782L0.322409 4.68363C-0.10747 4.25405 -0.10747 3.55727 0.322409 3.12761C0.752369 2.69787 1.44938 2.69788 1.87915 3.12745L4.09662 5.34379L9.12081 0.322181C9.55077 -0.107556 10.2478 -0.107231 10.6776 0.322181C11.1074 0.751837 11.1074 1.44838 10.6778 1.87828Z"
        fill="white"
      />
    </svg>
  );
}
