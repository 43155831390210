import { FormLogin } from "../FormLogin";
import bannerLiberdade from "@/assets/images/banner_liberdade.png";
import selo from "@/assets/images/selo-mes-do-cliente.png";
import percentage from "@/assets/images/percentage.png";
import dollar from "@/assets/images/dollar.png";
import styles from "./styles.module.css";

export function Desenrola() {
  return (
    <section id="inicio" className={styles.wrapper}>
      <div className={styles.inner_wrapper}>
        <div className={styles.banner_wrapper}>
          <div className={styles.box_wrapper}>
            <header className={styles.box}>
              <p>CONDIÇÕES IMPERDÍVEIS</p>

              <div className={styles.box_description}>
                <p>Feche seu acordo agora</p>
                <p>
                  <span>com </span>
                  <span>desconto de até</span>
                </p>
              </div>
              <img src={percentage} alt="Imagem escrito 95%" />
            </header>
            <p>*Oferta disponível conforme a condição do credor</p>
          </div>
        </div>
        <img src={selo} alt="" />
        <img src={bannerLiberdade} alt="Imagem da liberdade" />

        <img src={dollar} className={styles.dollar} alt="Sinal de Dollar" />
        <img src={dollar} className={styles.dollar} alt="Sinal de Dollar" />
        <img src={dollar} className={styles.dollar} alt="Sinal de Dollar" />
        <img src={dollar} className={styles.dollar} alt="Sinal de Dollar" />
        <img src={dollar} className={styles.dollar} alt="Sinal de Dollar" />
        <img src={dollar} className={styles.dollar} alt="Sinal de Dollar" />
        <FormLogin />
      </div>
    </section>
  );
}
