export function MobileShield(): JSX.Element {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.91667 44.3333C9.91667 49.1658 13.8342 53.0833 18.6667 53.0833H37.3333C42.1658 53.0833 46.0833 49.1658 46.0833 44.3333V32.6667C46.0833 31.7002 45.2998 30.9167 44.3333 30.9167C43.3668 30.9167 42.5833 31.7002 42.5833 32.6667V44.3333C42.5833 47.2328 40.2328 49.5833 37.3333 49.5833H18.6667C15.7672 49.5833 13.4167 47.2328 13.4167 44.3333V11.6667C13.4167 8.76716 15.7672 6.41666 18.6667 6.41666H23.3333C24.2998 6.41666 25.0833 5.63315 25.0833 4.66666C25.0833 3.70016 24.2998 2.91666 23.3333 2.91666H18.6667C13.8342 2.91666 9.91667 6.83416 9.91667 11.6667V44.3333Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9167 44.3333C23.9167 43.3668 24.7002 42.5833 25.6667 42.5833H30.3333C31.2998 42.5833 32.0833 43.3668 32.0833 44.3333C32.0833 45.2998 31.2998 46.0833 30.3333 46.0833H25.6667C24.7002 46.0833 23.9167 45.2998 23.9167 44.3333Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7401 6.74294C38.8857 6.3848 37.9038 6.3848 37.0494 6.74294L30.8292 9.35017C29.6087 9.86177 28.9656 10.9251 29.0274 11.925C29.2414 15.39 29.8751 17.6794 31.0145 19.5119C32.1621 21.3574 33.9325 22.9187 36.7675 24.7288C37.7439 25.3522 39.0499 25.3521 40.021 24.7306C42.8576 22.9152 44.6145 21.3392 45.7514 19.4908C46.8828 17.6515 47.5069 15.3672 47.7401 11.9392C47.8088 10.9295 47.1637 9.85459 45.9356 9.33983L39.7401 6.74294ZM35.6964 3.51504C37.4163 2.7941 39.3731 2.7941 41.0931 3.51504L47.2886 6.11192C49.6839 7.11595 51.4172 9.45465 51.232 12.1768C50.9783 15.9062 50.2741 18.8184 48.7326 21.3245C47.1967 23.8217 44.9362 25.7403 41.9077 27.6786C39.783 29.0383 37.0055 29.0334 34.8839 27.6788C31.8717 25.7555 29.5935 23.8549 28.0422 21.3601C26.4829 18.8522 25.7676 15.9229 25.5341 12.1408C25.3668 9.43334 27.0984 7.11893 29.4762 6.12226L35.6964 3.51504Z"
        fill="currentcolor"
      />
    </svg>
  );
}
