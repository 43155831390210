import { ArrowLeft } from "../../assets/icons/outline/ArrowLeft";
import {
  Container,
  Header,
  Progress,
  ProgressValue,
  StepAction,
  StepInfo,
  Title,
} from "./styles";

interface Props {
  title: string;
  current: number;
  max: number;
  handleStepBack?: () => void;
}

export function Step({ title, current, max, handleStepBack }: Props) {
  return (
    <Container>
      {!!handleStepBack && (
        <StepAction onClick={handleStepBack}>
          <ArrowLeft />
        </StepAction>
      )}
      <StepInfo>
        <Header>
          <Title>{title}</Title>
          <p>
            {current} de {max}
          </p>
        </Header>
        <Progress>
          <ProgressValue css={{ $$percentage: `${(current / max) * 100}%` }} />
        </Progress>
      </StepInfo>
    </Container>
  );
}
