import { styled } from "../../styles";

export const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  fontFamily: "$default",
});

export const Label = styled("label", {
  alignSelf: "start",
  lineHeight: "22px",
  color: "$primaryDarkest",
  padding: "3px $1",
});

export const HelperText = styled("span", {
  display: "flex",
  gap: 8,
  marginTop: 4,
  textAlign: "start",

  fontSize: "$xxs",
  lineHeight: "19px",

  svg: {
    size: 18,
  },

  variants: {
    helperTextType: {
      default: {
        color: "$neutralDark",
      },
      success: {},
      error: {
        color: "$plusTwoDark",
      },
    },
  },
  defaultVariants: {
    helperTextType: "default",
  },
});

export const Content = styled("div", {
  position: "relative",

  svg: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    marginRight: "18px",
    right: 0,
  },

  "&:has(svg) input": {
    paddingRight: 40,
  },

  variants: {
    isSuccess: {
      true: {
        svg: {
          color: "$secondaryMedium",
        },
        input: {
          borderColor: "$secondaryMedium",
        },
      },
    },
    isError: {
      true: {
        svg: {
          color: "$plusTwoDark",
        },
        input: {
          borderColor: "$plusTwoDark",
        },
        [`& + ${HelperText}`]: {
          color: "$plusTwoDark",
        },
      },
    },
  },
});

export const Currency = styled("span", {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  fontSize: "$sm",
  lineHeight: "25px",
  color: "$neutralMedium",
  marginLeft: 16,

  "& ~ input": {
    paddingLeft: 45,
  },
});

export const StyledInput = styled("input", {
  height: 25,
  borderRadius: 8,
  border: "1px solid $primaryMedium",
  outline: "none",
  padding: "15.5px 16px",
  fontFamily: "$default",
  fontWeight: "$bold",
  fontSize: "$sm",
  lineHeight: "25px",
  color: "$neutralDarkest",
  width: "stretch",

  "&::placeholder": {
    fontWeight: "$regular",
    color: "$neutralMedium",
  },
});
