export const colors = {
  background: "#F3F8FF",
  primaryDarkest: "#06357A",
  primaryDark: "#0C3BDD",
  primaryMedium: "#076BEE",
  primaryLight: "#CEE2FF",
  primaryLighter: "#E8F1FF",
  secondaryDarkest: "#006601",
  secondaryDark: "#008423",
  secondaryMedium: "#0CAD0F",
  secondaryLight: "#05DC09",
  secondaryLighter: "#D1EDDB",
  neutralDarkest: "#334156",
  neutralDark: "#556F97",
  neutralMedium: "#A0B4D1",
  neutralLighter: "#FFFFFF",
  plusOneDark: "#FBBB00",
  plusOneMedium: "#FBD300",
  plusOneLight: "#FFF5D9",
  plusTwoDarkest: "#A71E3D",
  plusTwoDark: "#D0375A",
  plusTwoMedium: "#FF6565",
  plusTwoLight: "#ECD1DE",
  plusThreeDark: "#A456E2",
};
