import { createStitches, defaultThemeMap } from "@stitches/react";
import { colors } from "./tokens/colors";
import { fonts } from "./tokens/fonts";
import { fontSizes } from "./tokens/fontSizes";
import { fontWeights } from "./tokens/fontWeights";
import { lineHeights } from "./tokens/lineHeights";
import { radii } from "./tokens/radii";
import { space } from "./tokens/space";

export const { styled } = createStitches({
  themeMap: {
    ...defaultThemeMap,
    padding: "space",
    height: "space",
    fontSize: "fontSizes",
  },
  theme: {
    colors,
    fonts,
    fontSizes,
    fontWeights,
    lineHeights,
    radii,
    space,
  },
  media: {
    max_width_480: "(max-width: 480px)",
    min_width_481: "(min-width: 481px)",
    max_width_768: "(max-width: 768px)",
    min_width_769: "(min-width: 769px)",
    max_width_1024: "(max-width: 1024px)",
    min_width_1025: "(min-width: 1025px)",
    max_width_1200: "(max-width: 1200px)",
    min_width_1201: "(min-width: 1201px)",
    min_width_1410: "(min-width: 1410px)",
  },
  utils: {
    size: (size: number) => ({ width: size, height: size }),
  },
});
