import { styled } from "../../../styles";

export const Container = styled("section", {
  display: "flex",
  justifyContent: "center",
  backgroundColor: "$primaryDark",
  minHeight: "calc(100vh - 228px)",
});

export const Content = styled("div", {
  display: "flex",
  maxWidth: 1160,
  gap: 32,
  padding: 16,

  "@max_width_768": {
    flexDirection: "column",
    overflow: "hidden",
    width: "100%",
  },

  "@min_width_769": {
    padding: "$4 $4",
    alignItems: "center",
    justifyContent: "center",
  },

  "@min_width_1201": {
    gap: 70,
  },
});

export const HeadingContainer = styled("div", {
  position: "relative",
  marginTop: 8,
  paddingBottom: 220,
});

export const OfferLabel = styled("span", {
  display: "flex",
  width: "fit-content",
  justifyContent: "center",
  alignItems: "center",
  gap: 8,
  padding: "$1 $3",
  backgroundColor: "$primaryLight",
  borderRadius: "20px",
  fontWeight: "$bold",
  fontSize: "$xxs",
  lineHeight: "19px",
  color: "$primaryMedium",
  marginBottom: 16,

  svg: {
    size: 20,
  },
});

export const Heading = styled("h1", {
  fontWeight: "$extraBold",
  fontSize: "$xxl",
  lineHeight: "55px",
  color: "$neutralLighter",
  marginBottom: 8,

  "@max_width_768": {
    fontSize: "$lg",
    lineHeight: "33px",
  },
});

export const Description = styled("p", {
  fontSize: "$md",
  lineHeight: "27px",
  color: "$primaryLight",

  "@max_width_768": {
    fontSize: "$xxs",
    lineHeight: "19px",
  },
});

export const PeopleCards = styled("div", {
  display: "flex",
  gap: 20,
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",

  "@min_width_481": {
    bottom: 0,
  },
});

export const PeopleCard = styled("div", {
  zIndex: 0,
  width: 220,
  height: 150,
  display: "flex",
  justifyContent: "center",
  marginTop: 36,
  borderRadius: 20,
  flexShrink: 0,
  left: "50%",
  alignItems: "end",
  variants: {
    variant: {
      primary: { backgroundColor: "$primaryMedium" },
      secondary: { backgroundColor: "$secondaryMedium" },
      terciary: { backgroundColor: "$plusOneDark" },
    },
  },

  img: {
    maxWidth: 200,
  },
});

export const BoxForm = styled("div", {
  padding: "48px 34px",
  backgroundColor: "$neutralLighter",
  border: "1.5px solid $primaryLight",
  borderRadius: "20px",
  boxSizing: "border-box",
  zIndex: 1,
  flexShrink: 0,

  "@max_width_480": {
    padding: 24,
    maxWidth: 328,
    margin: "auto",
    width: "100%",
  },

  "@min_width_481": {
    margin: "auto",
  },

  "@min_width_769": {
    width: 360,
  },

  "@min_width_1025": {
    alignItems: "start",
    width: 492,
  },
});

export const BoxFormHeader = styled("header", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 8,
});

export const BoxFormHeading = styled("h2", {
  fontWeight: "$extraBold",
  fontSize: "$lg",
  lineHeight: "33px",
  color: "$neutralDarkest",
});

export const DealDebtLabel = styled("span", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 8,
  padding: "$1 $3",
  backgroundColor: "$secondaryLighter",
  borderRadius: "12px",
  fontWeight: "$bold",
  fontSize: "$xxs",
  lineHeight: "19px",
  color: "$secondaryDark",
  width: "fit-content",

  "@max_width_768": {
    width: "max-content",
    maxWidth: "100%",
  },
});

export const Form = styled("form", {
  marginTop: 16,
  marginBottom: 24,
  display: "flex",
  flexDirection: "column",
  gap: 16,

  "& + button": {
    width: "100%",
  },
});
