export function HandCreditCard() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66016 18.6678L6.06229 12.9975C6.45385 12.3449 7.02338 11.8175 7.70408 11.4772L8.48466 11.0869C9.04032 10.8091 9.65302 10.6644 10.2743 10.6644H13.3313"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.32812 28.0051L13.0138 25.2279C14.0865 24.8403 15.0068 24.1191 15.6396 23.1702L19.6318 17.1838C20.2124 16.3131 20.0977 15.1536 19.3577 14.4135V14.4135C18.5005 13.5564 17.1108 13.5562 16.2535 14.4132L13.3315 17.334H10.6637"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6431 18.6678H26.2502C27.987 18.6363 29.3697 17.2033 29.3392 15.4665V9.86418C29.3697 8.12736 27.987 6.69439 26.2502 6.66284H16.4215C14.6847 6.69439 13.302 8.12736 13.3325 9.86418V9.86418V17.334"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
