export function Minus() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.06641 12.7267C4.06641 12.3125 4.40219 11.9767 4.81641 11.9767H19.1855C19.5998 11.9767 19.9355 12.3125 19.9355 12.7267C19.9355 13.141 19.5998 13.4767 19.1855 13.4767H4.81641C4.40219 13.4767 4.06641 13.141 4.06641 12.7267Z"
        fill="currentcolor"
      />
    </svg>
  );
}
