export function ArrowLeft(): JSX.Element {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.78747 13.0248C3.50183 12.7332 3.50183 12.2668 3.78747 11.9752L10.796 4.82062C11.0859 4.52473 11.5607 4.51983 11.8566 4.80969C12.1525 5.09955 12.1574 5.5744 11.8675 5.87029L6.10783 11.75L20.6765 11.75C21.0907 11.75 21.4265 12.0858 21.4265 12.5C21.4265 12.9142 21.0907 13.25 20.6765 13.25L6.10783 13.25L11.8675 19.1297C12.1574 19.4256 12.1525 19.9005 11.8566 20.1903C11.5607 20.4802 11.0859 20.4753 10.796 20.1794L3.78747 13.0248Z"
        fill="currentcolor"
      />
    </svg>
  );
}
