import { styled } from "../../styles";

export const Container = styled("footer", {
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  justifyContent: "center",
  background: "$background",
  borderTop: "1.5px solid $primaryLight",
  boxSizing: "border-box",
  minHeight: 128,
  gap: 16,
  zIndex: 2,
  paddingBottom: 16,

  "@max_width_480": {
    minHeight: 142.5,
    paddingRight: 16,
    paddingLeft: 16,
  },

  button: {
    height: "auto",
  },
});

export const Text = styled("p", {
  fontWeight: "$regular",
  fontSize: "$xxxs",
  color: "$neutralDarkest",
  lineHeight: "12px",
  opacity: 0.5,
});
