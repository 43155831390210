import { Navigate, Outlet } from "react-router-dom";
import { SituacaoCadastro } from "../enums/SituacaoCadastro";
import { useAuth } from "./hook";

export function Protected() {
  const { auth } = useAuth();

  if (!auth.user) {
    return <Navigate to="/" />;
  }

  if (auth.user.statusCadastro === SituacaoCadastro.Incompleto) {
    return <Navigate to="/negociar" />;
  }

  return <Outlet />;
}
