export function Help() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.54785C7.33201 3.54785 3.54785 7.33201 3.54785 12C3.54785 13.2789 3.83137 14.4894 4.33828 15.5741C4.40205 15.7105 4.42326 15.863 4.39916 16.0117L3.76117 19.9467C3.73379 20.1155 3.88031 20.2614 4.04905 20.2333L7.94722 19.5836C8.09804 19.5584 8.25294 19.58 8.39116 19.6454C9.48469 20.1625 10.7075 20.4522 12 20.4522C16.668 20.4522 20.4521 16.668 20.4521 12C20.4521 7.33201 16.668 3.54785 12 3.54785ZM2.04785 12C2.04785 6.50358 6.50358 2.04785 12 2.04785C17.4964 2.04785 21.9521 6.50358 21.9521 12C21.9521 17.4964 17.4964 21.9522 12 21.9522C10.5658 21.9522 9.20075 21.6483 7.96715 21.1009L4.29565 21.7129C3.11442 21.9097 2.08886 20.8887 2.28051 19.7066L2.88229 15.9949C2.34547 14.7712 2.04785 13.4194 2.04785 12Z"
        fill="currentcolor"
      />
      <path
        d="M11.9871 13.8064C11.8356 13.8064 11.716 13.7585 11.6283 13.6628C11.5485 13.5591 11.5086 13.4236 11.5086 13.2561C11.5086 13.0088 11.5365 12.7776 11.5924 12.5622C11.6562 12.3389 11.7559 12.1156 11.8914 11.8923C12.027 11.669 12.2065 11.4337 12.4298 11.1865C12.5972 10.9951 12.7288 10.8276 12.8245 10.6841C12.9282 10.5325 13 10.389 13.0399 10.2534C13.0877 10.1178 13.1116 9.97827 13.1116 9.83472C13.1116 9.57154 13.0159 9.36418 12.8245 9.21265C12.6331 9.06112 12.362 8.98536 12.0111 8.98536C11.6921 8.98536 11.401 9.02922 11.1378 9.11695C10.8826 9.1967 10.6234 9.31234 10.3602 9.46387C10.1848 9.56755 10.0252 9.6154 9.88169 9.60743C9.74611 9.59148 9.63446 9.53964 9.54673 9.45191C9.45901 9.36418 9.40318 9.25652 9.37926 9.12891C9.35533 9.00131 9.37527 8.86972 9.43907 8.73414C9.50287 8.59856 9.61851 8.47495 9.78599 8.3633C10.113 8.14797 10.4878 7.98447 10.9105 7.87282C11.3332 7.75319 11.7319 7.69338 12.1068 7.69338C12.6092 7.69338 13.0478 7.77712 13.4227 7.9446C13.8055 8.11208 14.1006 8.34336 14.3079 8.63844C14.5233 8.93352 14.6309 9.28044 14.6309 9.6792C14.6309 9.92643 14.595 10.1617 14.5233 10.385C14.4515 10.6003 14.3358 10.8197 14.1763 11.043C14.0168 11.2583 13.7975 11.4936 13.5184 11.7488C13.2712 11.9721 13.0718 12.1754 12.9202 12.3589C12.7767 12.5423 12.669 12.7178 12.5972 12.8852C12.5334 13.0527 12.4896 13.2322 12.4657 13.4236C12.4577 13.5352 12.4098 13.6269 12.3221 13.6987C12.2424 13.7705 12.1307 13.8064 11.9871 13.8064ZM11.9632 16.3066C11.6921 16.3066 11.4727 16.2229 11.3053 16.0554C11.1458 15.8879 11.066 15.6726 11.066 15.4094C11.066 15.1542 11.1458 14.9468 11.3053 14.7873C11.4727 14.6198 11.6921 14.5361 11.9632 14.5361C12.2424 14.5361 12.4577 14.6198 12.6092 14.7873C12.7687 14.9468 12.8485 15.1542 12.8485 15.4094C12.8485 15.6726 12.7687 15.8879 12.6092 16.0554C12.4577 16.2229 12.2424 16.3066 11.9632 16.3066Z"
        fill="currentcolor"
      />
    </svg>
  );
}
