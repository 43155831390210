import api from "../api";
import { AuthRequest, AuthResponse, PutContatoClienteBody } from "./types";

const API = "/cliente/api/AcessoWeb";

export const Auth = {
  attempt: (credentials: AuthRequest) =>
    api.post<AuthResponse>(API, {
      ...credentials,
    }),

  contato: (data: PutContatoClienteBody) =>
    api.put(`${API}/ContatoCliente`, data),
};
