export function MoneyArrow() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0004 3.995C20.7888 3.995 24.6706 7.87681 24.6706 12.6653C24.6706 17.4537 20.7888 21.3355 16.0004 21.3355C11.2119 21.3355 7.33008 17.4537 7.33008 12.6653C7.33008 7.87681 11.2119 3.995 16.0004 3.995"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33203 21.3356H22.6709"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0056 16H25.3383C26.8117 16 28.0061 17.1944 28.0061 18.6678V25.3372C28.0061 26.8106 26.8117 28.005 25.3383 28.005H6.66387C5.1905 28.005 3.99609 26.8106 3.99609 25.3372V18.6678C3.99609 17.1944 5.1905 16 6.66387 16H7.99659"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.332 13.3322L15.9998 16L18.6676 13.3322"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0009 9.33057V16"
        stroke="#076BEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
