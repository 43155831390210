import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logos/logo.svg";
import logoLettering from "../../../assets/logos/logo-lettering.svg";
import {
  Container,
  Links,
  ImageLogo,
  ImageLogoLettering,
  ToggleMenu,
  LogoContainer,
  Logos,
  NavButton,
  Content,
  LinkRenegociarDivida,
} from "./styles";
import { Menu } from "../../../assets/icons/outline/Menu";
import { PaymentHistory } from "../../../assets/icons/outline/PaymentHistory";
import { ShieldCheck } from "../../../assets/icons/outline/ShieldCheck";
import { Help } from "../../../assets/icons/outline/Help";
import { Home } from "../../../assets/icons/outline/Home";

const HASH_BENEFITS = "#beneficios";
const HASH_DOUBTS = "#duvidas";

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  function handleScroll() {
    const { hash } = window.location;

    const scrollTo = document.getElementById(hash?.replace("#", ""));

    if (!scrollTo) return;

    window.scrollTo({
      top: scrollTo.offsetTop - 100,
      behavior: "smooth",
    });
  }

  function navbarHighlight(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      const navButton = document.getElementById(
        `button-${entry.target.getAttribute("id")}`
      );

      if (entry.isIntersecting && entry.intersectionRatio > 0.75) {
        navButton?.classList?.add("active");
      } else {
        navButton?.classList?.remove("active");
      }
    });
  }

  useLayoutEffect(() => {
    handleScroll();
  }, [window.location?.hash]);

  useEffect(() => {
    const observer = new IntersectionObserver(navbarHighlight, {
      threshold: 1,
    });

    const sectionInit = document.getElementById("inicio");
    const sectionBenefits = document.getElementById("beneficios");
    const sectionDoubts = document.getElementById("duvidas");

    if (sectionInit && sectionBenefits && sectionDoubts) {
      observer.observe(sectionInit);
      observer.observe(sectionBenefits);
      observer.observe(sectionDoubts);
    }
  }, []);

  return (
    <Container>
      <Content>
        <LogoContainer>
          <Logos>
            <ImageLogo src={logo} alt="Logo" height={60} />
            <ImageLogoLettering src={logoLettering} />
          </Logos>
          <ToggleMenu>
            <label htmlFor="toggle">
              <Menu />
            </label>
          </ToggleMenu>
        </LogoContainer>
        <input
          type="checkbox"
          id="toggle"
          checked={isMenuOpen}
          onChange={(event) => setIsMenuOpen(event.target.checked)}
          hidden
        />
        <Links>
          <NavButton
            id="button-inicio"
            onClick={() => {
              navigate("/");
              setIsMenuOpen(false);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <Home /> Início
          </NavButton>
          <NavButton
            id="button-beneficios"
            onClick={() => {
              setIsMenuOpen(false);
              navigate({ pathname: "/", hash: HASH_BENEFITS });
              handleScroll();
            }}
          >
            <ShieldCheck /> Benefícios
          </NavButton>
          <NavButton
            id="button-duvidas"
            onClick={() => {
              setIsMenuOpen(false);
              navigate({ pathname: "/", hash: HASH_DOUBTS });
              handleScroll();
            }}
          >
            <Help /> Dúvidas
          </NavButton>
          <LinkRenegociarDivida
            id="renegociar-divida"
            to={{ pathname: "/negociar" }}
          >
            <PaymentHistory /> Negociar dívida
          </LinkRenegociarDivida>
        </Links>
      </Content>
    </Container>
  );
}
