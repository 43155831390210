import { styled } from "../styles";

export const Text = styled("p", {
  fontFamily: "$default",

  variants: {
    fontSize: {
      xxxs: { fontSize: "0.75rem" },
      xxs: { fontSize: "0.875rem" },
      xs: { fontSize: "1rem" },
      sm: { fontSize: "1.125rem" },
      md: { fontSize: "1.25rem" },
      lg: { fontSize: "1.5rem" },
      xl: { fontSize: "2rem" },
      xxl: { fontSize: "2.5rem" },
      xxxl: { fontSize: "3rem" },
      display: { fontSize: "4rem" },
    },
  },
  defaultVariants: {
    fontSize: "xs",
  },
});
