import { PropsWithChildren, useMemo, useState } from "react";
import { SimulationStep } from "../../enums/SimulationStep";
import { OpcoesParcelamento } from "../../services/renegociacao/types";
import { SimulationContext } from "./context";

export function SimulationProvider({ children }: PropsWithChildren) {
  const [simulationStep, setSimulationStep] = useState(
    SimulationStep.BestOffer
  );
  const [bestOffer, setBestOffer] = useState<OpcoesParcelamento>();
  const [installments, setInstallments] = useState(1);
  const [installmentOptions, setInstallmentOptions] =
    useState<OpcoesParcelamento>();

  const providerValues = useMemo(
    () => ({
      bestOffer,
      setBestOffer,
      installments,
      setInstallments,
      installmentOptions,
      setInstallmentOptions,
      simulationStep,
      setSimulationStep,
    }),
    [[]]
  );

  return (
    <SimulationContext.Provider value={providerValues}>
      {children}
    </SimulationContext.Provider>
  );
}
